import { Component, OnInit } from '@angular/core';
import {  Program, Events } from '../events/events';
import { FileUpload } from './../../Models/FileUpload';
import { EventsService } from 'src/app/Services/events.service';
import { UploadFileServiceService } from 'src/app/Services/upload-file-service.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.css']
})
export class ProgramsComponent implements OnInit {
  promise: Promise<void>;
  msgs: Message[] = [];

  programs: Program[] = [];
  eventsList: Events[] = [];
  eventsLisDropDown: any[] = [];
  selectedProgramFiles: any;
  programData: Program[] = [];
  editItemProgram: any;
  ProgramForm: any;
  addProgramFormStatus: boolean;

  currentFileUploadProgram: FileUpload;
  progressProgram: { percentage: number } = { percentage: 0 }


  constructor(private eventsService: EventsService, private fileUploadService: UploadFileServiceService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.LoadEvents();

  }

  LoadEvents() {
    this.eventsLisDropDown=[];
    let value = {
      label: "-- Select --",
      value: null ,

    }
    this.eventsLisDropDown.push(value)
    this.eventsService.GetEvents()
      .subscribe(
        (events: Events[]) => {
          this.eventsList = events;
          events = events.filter(x => x.Active === true);       
          this.eventsList.forEach((item, index) => {
            events.forEach((item1) => {
              if (item.EventTitle === item1.EventTitle) {
                let value = {
                  label: item.EventTitle,
                  value: index,

                }
                this.eventsLisDropDown.push(value)
              }
            });
          });
          this.LoadPrograms();
          console.log(this.eventsLisDropDown);
        },
        (error: any) => {
          alert(error);
        }
      );

  }


  LoadPrograms() {
    this.eventsService.GetPrograms()
      .subscribe(
        (profile: Program[]) => {
          if (profile != undefined && profile.length > 0) {
            this.eventsList.forEach((item, index) => {
              profile.forEach((item1, index1) => {
                if (item1.EventId === index) {
                  item1.Event = item
                  console.log(item);
                }

              });
            });
            this.programs = profile;
            this.buildProgramPageForm(this.programs);
          }
        },
        (error: any) => {
          alert(error);
        })
  }



  //#region on Program 
  buildProgramPageForm(programData) {
    const page = programData;
    this.ProgramForm = this.formBuilder.group({
      EventId: [page ? page.EventId : '', Validators.required],
      Title: [page ? page.Title : '', Validators.required],
      Description: [page ? page.Description : ''],
      Image: [page ? page.Image : ''],
      Active: [page ? page.Active : false],
      ProgramId: [page ? page.ProgramId : ''],
      ProgramUrl: [page ? page.ProgramUrl : ''],
    });
  }



  findSelectedItemProgramIndex(item): number {
    return this.programs.indexOf(item);
  }


  onNewProgramSubmit(formData) {
    let eventDays = [];
    if (this.currentFileUploadProgram != null && this.currentFileUploadProgram.url != null) {
      if (formData.value.Image != null && formData.value.Image != "") {
        this.fileUploadService.removeFireBaseImage(formData.value.Image);
        formData.value.Image = '';
      }

      formData.value.Image = this.currentFileUploadProgram.url;

    }
    if (this.programs && this.programs.length > 0) {
      eventDays = [...this.programs];
    }
    if (this.editItemProgram) {
      eventDays[this.findSelectedItemProgramIndex(this.editItemProgram)] = formData.value;
    } else {
      eventDays.push(formData.value);
    }

    if (!this.programs) {
      this.programs = [];
    }
    let valueIndex = eventDays.indexOf(formData.value);
    eventDays.forEach((item, index) => {
      item.Event = null
      if (index == valueIndex) {
        item.ProgramId = valueIndex;
      }
    });

    this.promise = this.eventsService.SavePrograms(eventDays);
    this.promise.then(_ => this.msgs.push({ severity: 'success', summary: 'Success Message', detail: 'Saved' }))
      .catch(err => this.msgs.push({ severity: 'error', summary: 'Error Message', detail: err }));
    this.toggleAddProgramForm['eventDays'] = eventDays;
    this.editItemProgram = null;

    this.currentFileUploadProgram = new FileUpload(null);
    this.toggleAddProgramForm();
    this.progressProgram.percentage = 0;
    this.addProgramFormStatus = false;
    this.LoadEvents();
  }

  toggleAddProgramForm() {
    
    this.editItemProgram = null;
    this.ProgramForm = this.formBuilder.group({
      EventId: [null, Validators.required],
      Title: ['', Validators.required],
      Description: '',
      Image: '',
      Active: false,
      ProgramId:'',
      ProgramUrl:''
    });
    this.addProgramFormStatus = true;

  }



  editProgramForm(eventData) {
    debugger
    this.editItemProgram = eventData;
    // this.editItemProgram.Event = null;
    this.addProgramFormStatus = true;
    if (this.addProgramFormStatus) {
      this.buildProgramPageForm(this.editItemProgram)
    }

  }


  deleteProgramForm(programs) {
    let index=this.findSelectedItemProgramIndex(programs);
    if(index>=0){
    this.programs.splice(index, 1);
    this.eventsService.SavePrograms(this.programs);
    }
  }

  //for program
  selectFileProgram(event) {
    const fileNew = event.target.files.item(0);
    if (fileNew.type.match('image.*')) {
      this.selectedProgramFiles = event.target.files;
      this.uploadForProgram();
    } else {
      alert('invalid format!');
    }

  }

  uploadForProgram() {

    const fileNew = this.selectedProgramFiles.item(0)
    this.selectedProgramFiles = undefined
    this.currentFileUploadProgram = new FileUpload(fileNew);
    this.fileUploadService.pushFileToStorage(this.currentFileUploadProgram, this.progressProgram)

  }
}
