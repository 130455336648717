export interface ISessionQuestions{
    ProgramId:number;
    RoomId:number;
    SessionId:number;
    Questions:UserQuestions[];
    UserQuestionId:number;
}

export class SessionQuestions implements ISessionQuestions{
    ProgramId:number;
    RoomId:number;
    SessionId:number;
    Questions:UserQuestions[];
    UserQuestionId:number;
}

export interface IUserQuestions{
   
    Question:string;
    Time:string;
    UserName:string
    Likes:number;
}

export class UserQuestions implements IUserQuestions{
  
    Question:string;
    Time:string;
    UserName:string
    Likes:number;
}