import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from "@angular/core";

import { AuthService } from './Services/auth-service.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(public auth: AuthService, public router: Router) {}
 
  canActivate(): boolean {  
    // if (!this.auth.user) {
    //   this.router.navigate(['/login-component']);
    //   return false;
    // }
    let localStorageUservalue = localStorage.getItem('user');
    if(localStorageUservalue==undefined || localStorageUservalue=="null"){
      this.router.navigate(['/login-component']);
      return false;
    }
    else{
      return true;
    }
   
  }
}