import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import * as _ from 'lodash';


@Injectable({
  providedIn: 'root'
})
export class SessionTypeService{  

    basepathSessionType:string = "/SessionType/";

    constructor(private fireBase: AngularFireDatabase) {  }

    GetAllSessionTypes() {    
        return this.fireBase.object(this.basepathSessionType).valueChanges();
    }

    SaveSessionType(sessionTypeObject:any) {    
      return this.fireBase.object(this.basepathSessionType).set(sessionTypeObject);
  }
}
