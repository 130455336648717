import { Component, OnInit } from '@angular/core';
import { ProfilesService } from 'src/app/Services/profiles.service';
import { Profile } from './profiles';
import { FileUpload } from './../../Models/FileUpload';
import { UploadFileServiceService } from 'src/app/Services/upload-file-service.service';
import { Message } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-profiles',
  templateUrl: './profiles.component.html',
  styleUrls: ['./profiles.component.css']
})
export class ProfilesComponent implements OnInit {

  promise: Promise<void>
  profiles: Profile[] = [];
  selectedFiles: any;
  profileData;
  currentFileUpload: FileUpload;
  progress: { percentage: number } = { percentage: 0 }
  msgs: Message[] = [];
  isNewProfile: boolean;
  editItemProfile: any;
  ProfileForm: any;
  addProfileFormStatus: boolean;
  encryptSecretKey: string = "BSGE";


  constructor(private profileService: ProfilesService, private fileUploadService: UploadFileServiceService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.LoadProfiles();
  }


  LoadProfiles() {
    this.profileService.getProfilesList()
      .subscribe(
        (profile: Profile[]) => {
          this.profiles = profile;
          this.buildPageForm(this.profiles);
        },
        (error: any) => {
          alert(error);
        }
      );

  }

  buildPageForm(profileData) {
    const page = profileData;

    this.ProfileForm = this.formBuilder.group({
      Title: [page ? page.Title : ''],
      Forename: [page ? page.Forename : '', Validators.required],
      Surname: [page ? page.Surname : ''],
      Qualification: [page ? page.Qualification : ''],
      Description: [page ? page.Description : ''],
      Biograph: [page ? page.Biograph : ''],
      Mobile: [page ? page.Mobile : ''],
      Image: [page ? page.Image : ''],
      // Email:[page ? page.Email:'',[Validators.required,Validators.email]],
      // Password:[page ? page.Password:'',Validators.required],
      Active: [page ? page.Active : false, Validators.required],
      ProfileId: [page ? page.ProfileId : ''],
    });
  }



  findSelectedItemIndex(item): number {
    return this.profiles.indexOf(item);
  }


  onNewProfileSubmit(formData) {
    let eventDays = [];

    if (this.currentFileUpload != null && this.currentFileUpload.url != null) {
      if (formData.value.Image != null && formData.value.Image != "") {
        this.fileUploadService.removeFireBaseImage(formData.value.Image);
        formData.value.Image = '';
      }
      debugger
      formData.value.Image = this.currentFileUpload.url;

    }
    if (this.profiles && this.profiles.length > 0) {
      eventDays = [...this.profiles];
    }
    if (this.editItemProfile) {
      eventDays[this.findSelectedItemIndex(this.editItemProfile)] = formData.value;
    } else {
      eventDays.push(formData.value);
    }

    if (!this.profiles) {
      this.profiles = [];
    }

    let valueIndex = eventDays.indexOf(formData.value);
    eventDays.forEach((item, index) => {

      if (index == valueIndex) {
        item.ProfileId = valueIndex;
      }
    });
   console.log(eventDays);
    this.promise = this.profileService.createProfile(eventDays);
    this.promise.then(_ => this.msgs.push({ severity: 'success', summary: 'Success Message', detail: 'Saved' }))
      .catch(err => this.msgs.push({ severity: 'error', summary: 'Error Message', detail: err }));
    this.toggleAddProfileForm['eventDays'] = eventDays;
    this.editItemProfile = null;
    this.toggleAddProfileForm();
    this.progress.percentage = 0;
    this.addProfileFormStatus = false;
  }

  toggleAddProfileForm() {
    this.isNewProfile = true;
    this.editItemProfile = null;
    this.ProfileForm = this.formBuilder.group({
      Title: '',
      Forename: ['', Validators.required],
      Surname: '',
      Qualification: '',
      Description: '',
      Biograph: '',
      Mobile: '',
      Active: false,
      Image: '',
      // Email:['', [Validators.required,Validators.email]],
      // Password:['', Validators.required],
      ProfileId: ''
    });
    this.addProfileFormStatus = true;

  }



  editProfileForm(profileData) {
    this.isNewProfile = false;
    // if(profileData!=undefined && profileData.Password !="" && profileData.Password!=undefined){
    //   profileData.Password= this.decryptData(profileData.Password);

    // }
    this.editItemProfile = profileData;
    this.addProfileFormStatus = true;
    if (this.addProfileFormStatus) {
      this.buildPageForm(this.editItemProfile)
    }

  }


  deleteProfileForm(profile) {
    debugger
    let index = this.findSelectedItemIndex(profile);
    if (index >= 0) {
      this.profiles.splice(index, 1);
      this.profileService.createProfile(this.profiles);
    }
  }



  //#region  Helpers
  //Upload Image
  ////Image Upload
  selectFile(event) {
    const file = event.target.files.item(0);
    if (file.type.match('image.*')) {
      this.selectedFiles = event.target.files;
      this.upload();
    } else {
      alert('invalid format!');
    }

  }

  upload() {

    const file = this.selectedFiles.item(0)
    this.selectedFiles = undefined
    this.currentFileUpload = new FileUpload(file);
    this.fileUploadService.pushFileToStorage(this.currentFileUpload, this.progress)

  }


  encryptData(data) {
    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), this.encryptSecretKey).toString();
    } catch (e) {
      console.log(e);
    }
  }

  decryptData(data) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  //#endregion


}
