import { Component, OnInit } from '@angular/core';
import { AppUsersService } from 'src/app/Services/app-users.service';
import { FormBuilder, Validators, FormArray } from '@angular/forms';
import { Message } from 'primeng/api';
import { AppUsers } from './app-users';
import { Pipe, PipeTransform } from '@angular/core';

@Component({
  selector: 'app-app-users',
  templateUrl: './app-users.component.html',
  styleUrls: ['./app-users.component.css']
})
export class AppUsersComponent implements OnInit {

  promise: Promise<void>

  msgs: Message[] = [];


  appUsersList: AppUsers[] = [];

  // eventData;
  isNewAppUser: boolean;
  editItemAppUser: any;
  AppUserForm: any;
  addAppUsersFormStatus: boolean;
  appUserId: any;
  objectKeys = Object.keys;
  dataExists: boolean = true;
  AppUsers: AppUsers = new AppUsers();
  $key: string = "";
  constructor(private appUsersService: AppUsersService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.LoadAppUsersList();
  }



  LoadAppUsersList() {

    this.appUsersService.getAppUsersList()
      .subscribe(
        (appUsersList: AppUsers[]) => {
          this.appUsersList = [];
          for (let key in appUsersList) {
            this.AppUsers = new AppUsers();
            this.AppUsers = appUsersList[key];
            this.AppUsers.$key = key;
            this.appUsersList.push(this.AppUsers);
          }
        },
        (error: any) => {
          alert(error);
        }
      );

  }



  buildPageForm(appUserData) {
    const page = appUserData;
    this.AppUserForm = this.formBuilder.group({
      FirstName: [page ? page.FirstName : '', Validators.required],
      LastName: [page ? page.LastName : ''],
      Email: [page ? page.Email : '', [Validators.required, Validators.email]],
      Password: [page ? page.Password : ''],
      Telephone: [page ? page.Telephone : ''],
      VerifiedStatus: [page ? page.VerifiedStatus : false],
      UserType: [page ? page.UserType : ''],
      IsSuspended: [page ? page.IsSuspended : false]
    });
  }


  editAppUserForm(appUserData: any) {
    this.isNewAppUser = false;
    // this.appUserId = appUserId;
    this.editItemAppUser = appUserData;
    this.addAppUsersFormStatus = true;
    this.$key = this.editItemAppUser.$key;
    if (this.addAppUsersFormStatus) {
      this.buildPageForm(this.editItemAppUser)
    }

  }


  onSubmit(formData) {

    this.appUsersService.updateAppUser(formData.value, this.$key).then(
      res => {
        this.msgs.push({ severity: 'success', summary: 'Success Message', detail: 'Saved' })
      })

      this.editItemAppUser = null;
      this.addAppUsersFormStatus = false;
      this.LoadAppUsersList();
  }
  findSelectedItemIndex(item): number {
    return this.appUsersList.indexOf(item);
  }
  deleteUser(appUser){
    
      
      let index = this.findSelectedItemIndex(appUser);
      if (index >= 0) {
       
        this.appUsersService.removeUser(appUser.$key);
        
      }
    
      
  }
}