import { Component, OnInit } from '@angular/core';

import { SessionQuestions, UserQuestions } from '../sessions/UserQuestions';
import { Sessions } from '../sessions/sessions';
import { SessionsService } from 'src/app/Services/sessions.service';

@Component({
  selector: 'app-viewquestions',
  templateUrl: './viewquestions.component.html',
  styleUrls: ['./viewquestions.component.css']
})
export class ViewquestionsComponent implements OnInit {
  sessionQuestions: SessionQuestions[] = [];
  sessionQuestionlist: SessionQuestions[] = [];
  questionList: UserQuestions[] = [];
  roomId: any;
  programid: any;
  sessionid: any;
  showSpinner: boolean = true;
  objectKeys = Object.keys;
  dataExists: boolean = true;
  session: Sessions;
  index: number;
  constructor(private sessionService: SessionsService) { }

  ngOnInit() {
    this.roomId = parseInt(localStorage.getItem("RoomId"));
    this.programid = parseInt(localStorage.getItem("ProgramId"));
    this.sessionid = parseInt(localStorage.getItem("SessionId"));
    this.GetUserQuestion();
    this.LoadSession();
  }

  GetUserQuestion() {
    this.sessionService.GeSessionsQuestionsSessions().subscribe((sessionQuestions: SessionQuestions[]) => {
      if (sessionQuestions != undefined && sessionQuestions != null) {
        this.sessionQuestionlist = sessionQuestions;
        this.questionList=[];
        sessionQuestions = sessionQuestions.filter(x => x.RoomId === this.roomId && x.ProgramId === this.programid && x.SessionId === this.sessionid)
        this.index = this.findSelectedItemIndex(sessionQuestions[0]);
      
        this.showSpinner = false;
        if (sessionQuestions != null && sessionQuestions != null && sessionQuestions.length > 0) {
          if (sessionQuestions[0].Questions != undefined && sessionQuestions[0].Questions != null) {
            debugger
            this.dataExists=true;
            this.questionList = sessionQuestions[0].Questions;
            this.sessionQuestions = sessionQuestions;
            this.showSpinner = false;
          }
          else {
           
            this.dataExists = false;
            this.showSpinner = false;
          }

        }
        else {
          this.dataExists = false;
          this.showSpinner = false;
        }
      } else {
        this.dataExists = false;
        this.showSpinner = false;
      }
    })


  }

  findSelectedItemIndex(item): number {
    return this.sessionQuestionlist.indexOf(item);
  }

  LoadSession() {
    this.sessionService.GetSessions().subscribe((session: Sessions[]) => {
      if (session != undefined) {
        session = session.filter(x => x.SessionsId === this.sessionid);
        if (session != undefined) {
          this.session = session[0];
        }
      }
    })
  }

  DeleteQuestion(key: any) {
    debugger
    if (this.questionList != undefined) {
      if (this.index >= 0) {
        this.sessionService.deleteUserQuestionsObject(this.index, key);
        this.GetUserQuestion();
      }
    }
  }
}
