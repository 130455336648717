import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
  providedIn: 'root'
})
export class UsernotificationService {
 
  baseUserNotificationPath:string = "/UserNotification/";
  constructor(private fireBase: AngularFireDatabase) { }

  getUserNotificationList() {
    return this.fireBase.object(this.baseUserNotificationPath).valueChanges();
  }

  saveUserNotification(data:any){
    return this.fireBase.object(this.baseUserNotificationPath).set(data);
  }

}
