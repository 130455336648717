import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { GuidGenerator } from 'src/app/Models/GuidGenerator';
import { CategoryService } from 'src/app/Services/category.service';
import { Category } from './category';
import { Message,SelectItem } from 'primeng/api';
import { CategoryPage } from '../category-pages/categorypage';
import { CategoryPageService } from 'src/app/Services/categorypage.service';
import { MenuService } from 'src/app/Services/menu.service';
import { Menu } from '../menus/Menu';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  promise: Promise<void>;
  msgs: Message[] = [];
  categories: Category[] = [];
  editItemCatgeory: any;
  CategoryForm: any;
  addCategoryFormStatus: boolean;
  //menuDropDown:SelectItem[]=[];
  pagesDropDown:SelectItem[]=[];
  ngOnInit(): void {
    this.LoadCategory();
    //this.LoadMenu();
    this.LoadPages();
  }


  constructor(
    private formBuilder: FormBuilder, 
    private categoryService: CategoryService,
    private categoryPageService: CategoryPageService,
    private menuService: MenuService,) {

  }

  LoadCategory() {
    this.categoryService.GetAllCategory()
      .subscribe(
        (categories: Category[]) => {
          this.categories = categories;
        },
        (error: any) => {
          alert(error);
        })
  }

   LoadPages() {
    let valueData = { label: " -- Select --", value: null }    
    this.pagesDropDown.push(valueData);
    this.categoryPageService.GetAllCategoryPages()
      .subscribe(
        (pages: CategoryPage[]) => {
          pages.forEach((item1) => {
            
            let value = {
              label: item1.Title,
              value: item1.CategoryPageId,

            }
            this.pagesDropDown.push(value)
          
        });
        },
        (error: any) => {
          alert(error);
        })
  }

  // LoadMenu() {
  //   let valueData = { label: " -- Select --", value: null }    
  //   this.menuDropDown.push(valueData);
  //   this.menuService.GetAllMenu()
  //     .subscribe(
  //       (menus: Menu[]) => {
  //         menus.forEach((item1) => {  
  //           debugger          
  //           let value = {
  //             label: item1.Title,
  //             value: item1.MenuId
  //           }
  //           this.menuDropDown.push(value)          
  //       });
  //       },
  //       (error: any) => {
  //         alert(error);
  //       })
  // }
  
  buildCategoryForm(programData) {
    const page = programData;
    this.CategoryForm = this.formBuilder.group({
      CategoryId: [page ? page.CategoryId : ''],      
      Title: [page ? page.Title : '', Validators.required],
      Active: [page ? page.Active : false],
     // MenuId: [page ? page.MenuId : ''],
     PageId:[page ? page.PageId : []] 
    });
  }

  findSelectedItemIndex(item): number {
    return this.categories.indexOf(item);
  }

  toggleAddCategoryForm() {
    this.editItemCatgeory = null;
    this.CategoryForm = this.formBuilder.group({
      CategoryId: '',      
      Title: ['', Validators.required],
      Active: false,
     // MenuId:''
     PageId:[]
    });
    this.addCategoryFormStatus = true;
  }



  editCategoryForm(eventData) {
    this.editItemCatgeory = eventData;
    this.addCategoryFormStatus = true;
    if (this.addCategoryFormStatus) {
      this.buildCategoryForm(this.editItemCatgeory)
    }
  }


  deleteCategoryForm(categoryPages) {
    let index = this.findSelectedItemIndex(categoryPages);
    if (index >= 0) {
      this.categories.splice(index, 1);
      this.categoryService.SaveCategory(this.categories);
    }
  }

  onSubmit(formData) {
    let categoryArray = [];
    debugger
    if (this.categories && this.categories.length > 0) {
      categoryArray = [...this.categories];
    }
    if (this.editItemCatgeory) {
      categoryArray[this.findSelectedItemIndex(this.editItemCatgeory)] = formData.value;
    } else {
      formData.value.CategoryId = GuidGenerator.GenerateGuid();
      categoryArray.push(formData.value);
    }

    if (!this.categories) {
      this.categories = [];
    }

    this.promise = this.categoryService.SaveCategory(categoryArray);
    console.log(categoryArray);
    this.promise.then(_ => this.msgs.push({ severity: 'success', summary: 'Success Message', detail: 'Saved' }))
      .catch(err => this.msgs.push({ severity: 'error', summary: 'Error Message', detail: err }));
    this.toggleAddCategoryForm['categoryArray'] = categoryArray;
    this.editItemCatgeory = null;
    this.toggleAddCategoryForm();
    this.addCategoryFormStatus = false;

  }

}
