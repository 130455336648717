export class GuidGenerator {

    public static GenerateGuid() {
        let guid = "";
        guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });

        return guid;

    }


}

export class BSGEHelper {
    public static GetDate(date) {
        var date1 = new Date(date),
            mnth = ("0" + (date1.getMonth() + 1)).slice(-2),
            day = ("0" + date1.getDate()).slice(-2);
        return [day,mnth,date1.getFullYear()].join("-");
    }
} 