import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  baseEventsPath:string = "/Events/";
  baseCustomEventsPath:string = "/CustomEventsList/";
  baseProgramPath:string = "/Programs/";
  constructor(private fireBase: AngularFireDatabase) { }



  GetEvents() {
    return this.fireBase.object(this.baseEventsPath).valueChanges();
  }

  SaveEvents(data:any){
    return this.fireBase.object(this.baseEventsPath).set(data);
  }

  

  GetPrograms(){
    return this.fireBase.object(this.baseProgramPath).valueChanges();
  }

  SavePrograms(data:any){
    return this.fireBase.object(this.baseProgramPath).set(data);
  }

  getCustEventList(){
    return this.fireBase.object(this.baseCustomEventsPath).valueChanges();
  }
}
