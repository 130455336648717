import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  basePath: string = "/Menus/";

  constructor(private fireBase: AngularFireDatabase) {
  }


  //menu crud
  SaveMenu(any: any[]) {
    const promise = this.fireBase.object(this.basePath).set(any);
    return promise;
  }

  GetAllMenu() {
    return this.fireBase.list(this.basePath).valueChanges();
  }



}
