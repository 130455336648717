import { Component, OnInit } from '@angular/core';
import { Message } from 'primeng/api';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ConfigurationService } from 'src/app/Services/configuration.service';

import { UploadFileServiceService } from 'src/app/Services/upload-file-service.service';
import { FileUpload } from 'src/app/Models/FileUpload';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css']
})
export class ConfigurationComponent implements OnInit {

  tokenList: any;
  promise: Promise<void>;
  msgs: Message[] = [];
  message;
  configurationData;
  configurationForm: FormGroup;
  loading = false;
  currentFileUploadCompanyLogo: FileUpload;
  progressCompanyLogo: { percentage: number } = { percentage: 0 }
  selectedCompanyLogo: any;
  currentFileUploadEmailLogo: FileUpload;
  progressEmailLogo: { percentage: number } = { percentage: 0 }
  selectedEmailLogo: any;
  showWifiDetails: boolean = false;

  constructor(private configurationService: ConfigurationService, private formBuilder: FormBuilder, private fileUploadService: UploadFileServiceService) {
    this.configurationData = this.configurationService.getConfiguration().subscribe(element => {
      this.configurationData = element;
      if (this.configurationData.EmailType == undefined || this.configurationData.EmailType == "") {
        this.configurationData.EmailType = "AttendeeEmail";
      }
     this.showWifiDetails = this.configurationData.IsWifiAvailable;
      this.buildFormGroup(this.configurationData);
      this.loading = true;

    });

  }

  ngOnInit() {
    this.loading = false;

  }


  buildFormGroup(configurationData) {
    let data = configurationData;
    this.loading = true;
    this.configurationForm = this.formBuilder.group({
      CompanyName: [data ? data.CompanyName : '', Validators.required],
      CompanyLogo: [data ? data.CompanyLogo : ''],
      CompanyEmailLogo: [data ? data.CompanyEmailLogo : ''],
      ApiUserName: [data ? data.ApiUserName : ''],
      ApiPassword: [data ? data.ApiPassword : ''],
      Facebook: [data ? data.Facebook : ''],
      Instagram: [data ? data.Instagram : ''],
      Twitter: [data ? data.Twitter : ''],
      EmailType: [data ? data.EmailType : ''],
      IsWifiAvailable: [data ? data.IsWifiAvailable : false],
      WifiNetworkName: [data ? data.WifiNetworkName : ''],
      WifiPassword: [data ? data.WifiPassword : '']
    });
  }

  onNewSubmit(formData) {
    debugger
    if (this.currentFileUploadCompanyLogo != null && this.currentFileUploadCompanyLogo.url != null) {
      if (formData.value.CompanyLogo != null && formData.value.CompanyLogo != "") {
        this.fileUploadService.removeFireBaseImage(formData.value.CompanyLogo);
        formData.value.CompanyLogo = '';
      }
      formData.value.CompanyLogo = this.currentFileUploadCompanyLogo.url;
    }
    if (this.currentFileUploadEmailLogo != null && this.currentFileUploadEmailLogo.url != null) {
      if (formData.value.CompanyEmailLogo != null && formData.value.CompanyEmailLogo != "") {
        this.fileUploadService.removeFireBaseImage(formData.value.CompanyEmailLogo);
        formData.value.CompanyEmailLogo = '';
      }
      formData.value.CompanyEmailLogo = this.currentFileUploadEmailLogo.url;

    }
    if (this.showWifiDetails == false) {
      formData.value.WifiNetworkName="";
      formData.value.WifiPassword="";
    }

    this.promise = this.configurationService.saveConfiguration(formData.value);
    this.promise.then(_ => this.msgs.push({ severity: 'success', summary: 'Success Message', detail: 'Saved' }))
      .catch(err => this.msgs.push({ severity: 'error', summary: 'Error Message', detail: err }));
    this.loading = true;
    this.progressCompanyLogo.percentage = 0;
    this.progressEmailLogo.percentage = 0;
    this.currentFileUploadEmailLogo = null;
    this.currentFileUploadCompanyLogo = null;
    this.buildFormGroup(formData.value);
  }


  UploadCompanyLogo(event) {
    const file = event.target.files.item(0);
    if (file.type.match('image.*')) {
      this.selectedCompanyLogo = event.target.files;
      this.CompanyLogo();
    } else {
      alert('invalid format!');
    }
  }

  CompanyLogo() {
    const file = this.selectedCompanyLogo.item(0)
    this.selectedCompanyLogo = undefined;
    this.currentFileUploadCompanyLogo = new FileUpload(file);
    this.fileUploadService.pushFileToStorage(this.currentFileUploadCompanyLogo, this.progressCompanyLogo)
  }


  UploadCompanyEmailLogo(event) {
    const file = event.target.files.item(0);
    if (file.type.match('image.*')) {
      this.selectedEmailLogo = event.target.files;
      this.CompanyEmailLogo();
    } else {
      alert('invalid format!');
    }
  }

  CompanyEmailLogo() {
    const file = this.selectedEmailLogo.item(0)
    this.selectedEmailLogo = undefined;
    this.currentFileUploadEmailLogo = new FileUpload(file);
    this.fileUploadService.pushFileToStorage(this.currentFileUploadEmailLogo, this.progressEmailLogo)
  }

  IsWifiAvailableventChanged(event: any) {
    this.showWifiDetails = event;
  }

}
