import { Component, OnInit } from '@angular/core';
import { TicketsService } from 'src/app/Services/ticketsservice.service';
import { Tickets } from './ticket';
import { Observable } from 'rxjs';
import { ConfirmationService, Message } from 'primeng/api';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css']
})
export class TicketsComponent implements OnInit {
  ticketList: any;
  dataExists: boolean = false;
  promise: any;
  msgs: Message[] = [];
  deletedTicketList: Tickets[] = [];

  ngOnInit() {
    this.LoadTicketList();

  }

  constructor(private ticketService: TicketsService, private confirmationService: ConfirmationService) { }

  LoadTicketList() {
    this.ticketService.getTicketsList()
      .subscribe(
        (ticketList: Tickets[]) => {
          for (let key in ticketList) {
            this.ticketList = ticketList[key];
          }
          this.LoadDeletedTicketList();
        },
        (error: any) => {
          alert(error);
        }
      );

  }

  LoadDeletedTicketList() {
    this.ticketService.getDeletedTicketsList()
      .subscribe(
        (ticketList: Tickets[]) => {
          if (ticketList == undefined || ticketList == null) {
            ticketList = [];
          }
          this.deletedTicketList = ticketList;
          let ticketListNew = this.ticketList;
          if (this.ticketList != undefined) {
            // this.ticketList = this.ticketList.forEach((element) => {
            //   this.deletedTicketList.filter((element1) => {
            //     console.log(element1 !== element)
            //     return element1 !== element;
            //   })
            // })
            this.deletedTicketList.forEach((item2) => {

              this.ticketList = this.ticketList.filter((item1) => {
                //use JSON.stringify() to compare tow objects
                return JSON.stringify(item1) !== JSON.stringify(item2);
              })
         
             })
             console.log(this.ticketList);
            //console.log(this.ticketList.length);

          }
        },
        (error: any) => {
          alert(error);
        }
      );

  }

  deletTicket(ticket: any) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this ticket?',
      accept: () => {
        debugger
        this.deletedTicketList.push(ticket);
        this.promise = this.ticketService.SaveDeletedTicketsList(this.deletedTicketList);
        this.promise.then(_ => this.msgs.push({ severity: 'success', summary: 'Success Message', detail: 'Saved' }))
          .catch(err => this.msgs.push({ severity: 'error', summary: 'Error Message', detail: err }));
        this.LoadTicketList();
      }
    });

  }

}
