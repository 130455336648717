import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ISessions, Sessions } from './sessions';
import { IRooms, Rooms } from '../rooms/rooms';
import { Message, SelectItem, ConfirmationService } from 'primeng/api';
import { RoomsservicesService } from 'src/app/Services/roomsservices.service';
import { FormBuilder, Validators } from '@angular/forms';
import { SessionsService } from 'src/app/Services/sessions.service';
import { Observable } from 'rxjs';
import { Session } from 'selenium-webdriver';
import { Profile } from '../profiles/profiles';
import { ProfilesService } from 'src/app/Services/profiles.service';
import { firestore } from 'firebase';
import { Teams } from '../teams/teams';
import { TeamsService } from 'src/app/Services/teams.service';
import { EventsService } from 'src/app/Services/events.service';
import { Program, Events } from '../events/events';
import { UserQuestions, SessionQuestions } from './UserQuestions';
import { SessionTypeService } from 'src/app/Services/session-type.service';
import { SessionType } from '../session-type/sessiontype';

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.css']
})
export class SessionsComponent implements OnInit {


  promise: Promise<void>;
  msgs: Message[] = [];
  eventsList: Events[];
  sessions: Sessions[] = [];
  roomsList: Rooms[] = [];
  roomsListDropDown: any[] = [];
  programList: Program[] = [];
  programListDropDown: any[] = [];
  sessionsTable: Sessions[] = [];
  editItemSession: any;
  SessionForm: any;
  addSessionFormStatus: boolean;
  profileList: Profile[] = [];
  sessionTypeList: SelectItem[] = [];
  teamsListSelectItem: SelectItem[] = [];
  speakerList: SelectItem[] = [];
  teamsListDropDown: any[];
  moderateList: SelectItem[] = [];
  SelectedSpeaker: Profile[];
  SelectedModerate: Profile[];
  radioButtonSelected: string = "";
  SelectedTeams: Teams[] = [];
  teamsList: Teams[];
  showSpeakers: boolean = false;
  showTeams: boolean = false;
  dayListDropDown: any[] = [];
  dayListDropDownFilter: any[] = [];
  sessionQuestions: SessionQuestions[] = [];
  isModertor: boolean = false;
  roomId: any;
  programId: any;
  day: any;
  selectedSessionType: SessionType[];


  constructor(private roomsService: RoomsservicesService,
    private formBuilder: FormBuilder, private sessionService: SessionsService, private profileService: ProfilesService,
    private confirmationService: ConfirmationService, private teamService: TeamsService, private eventService: EventsService,
    private sessionTypeService: SessionTypeService) {

  }

  ngOnInit() {

    this.LoadRooms();
    this.LoadProfiles();
    this.LoadTeams();
    // this.LoadDay();
    this.GetUserQuestion();
    this.LoadSessiontypes();
    this.LoadEvents();
    let emailUser = localStorage.getItem("loggedInUser");
    debugger
    if (emailUser == "moderators@akikodesign.com") {
      this.isModertor = true;
    }
  }

  LoadDay() {
    let value = {
      label: "-- Select --",
      value: null,

    }
    let array: any[] = [];
    array.push({ label: "-- Select --", value: null });
    array.push({ label: "Day 1", value: 1 });
    array.push({ label: "Day 2", value: 2 });
    this.dayListDropDown = array;
  }
  LoadPrograms() {

    this.programListDropDown = [];
    let value = {
      label: "-- Select --",
      value: null,

    }
    this.programListDropDown.push(value)
    this.eventService.GetPrograms()
      .subscribe(
        (programs: Program[]) => {

          if (programs != undefined && programs.length > 0) {
            this.programList = programs;

            programs = programs.filter(x => x.Active === true);
            programs.forEach((item1) => {
              this.programList.forEach((item, index) => {
                if (item1.Title === item.Title) {
                  let value = {
                    label: item1.Title,
                    value: index,

                  }
                  this.programListDropDown.push(value)
                }
              });
            });

          }
          this.LoadSessions();
        },
        (error: any) => {
          alert(error);
        })
  }

  LoadEvents() {

    this.eventService.GetEvents()
      .subscribe(
        (events: Events[]) => {

          this.eventsList = events;
        },
        (error: any) => {
          alert(error);
        })
  }


  LoadRooms() {

    this.roomsListDropDown = [];
    let value = {
      label: "-- Select --",
      value: null,

    }
    this.roomsListDropDown.push(value)
    this.roomsService.GetAllRooms()
      .subscribe(
        (room: Rooms[]) => {

          if (room != undefined && room.length > 0) {
            this.roomsList = room;

            room = room.filter(x => x.IsRoomActive === true);
            room.forEach((item1) => {
              this.roomsList.forEach((item, index) => {
                if (item1.RoomName === item.RoomName) {
                  let value = {
                    label: item1.RoomName,
                    value: index,

                  }
                  this.roomsListDropDown.push(value)
                }
              });
            });
            this.LoadPrograms();

          }

        },
        (error: any) => {
          alert(error);
        })
  }

  LoadSessions() {
    this.sessionService.GetSessions()
      .subscribe(
        (session: Sessions[]) => {

          if (session != undefined && session.length > 0) {

            session.forEach((item1, index1) => {
              this.roomsList.forEach((item, index) => {
                this.programList.forEach((itemProgra, indexProgram) => {
                  debugger
                  if (index == item1.RoomId) {
                    item1.RoomName = item.RoomName;
                    item1.RoomId = index;
                  }
                  if (indexProgram == item1.ProgramId) {
                    item1.ProgramName = itemProgra.Title;
                    item1.ProgramId = indexProgram;
                  }
                  let SStartDisplayTime = new Date(item1.SStartTime);
                  let SEndDisplayTime = new Date(item1.SEndTime);
                  item1.SStartDisplayTime = this.timeNow(SStartDisplayTime);
                  item1.SEndDisplayTime = this.timeNow(SEndDisplayTime);
                  // if (item1.Day == 1) {
                  //   item1.DayName = "Day 1";
                  // }
                  // if (item1.Day == 2) {
                  //   item1.DayName = "Day 2";
                  // }
                })

                this.sessions = session;
                this.sessionsTable = session;
              })

            })
          }


        })

  }


  LoadProfiles() {
    this.profileService.getProfilesList()
      .subscribe(
        (profile: Profile[]) => {
          this.speakerList=[];
          this.moderateList=[];
          this.profileList = profile;
          profile = profile.filter(x => x.Active === true);
          profile.forEach((item1) => {
            this.profileList.forEach((item, index) => {
              if (item.Active && item1.Forename === item.Forename && item1.Surname === item.Surname) {
                let value = {
                  label: item1.Forename+" "+item1.Surname,
                  value: index,

                }
                this.speakerList.push(value);
                this.moderateList.push(value);
              }
            });
          });

        },
        (error: any) => {
          alert(error);
        })
  }


  LoadSessiontypes() {
    this.sessionTypeService.GetAllSessionTypes()
      .subscribe(
        (sessiontypeList: SessionType[]) => {
          sessiontypeList.forEach((item1) => {
            debugger
            let value = {
              label: item1.Name,
              value: item1.SessionTypeId
            }
            this.sessionTypeList.push(value);
          });

        },
        (error: any) => {
          alert(error);
        })
  }


  LoadTeams() {

    this.teamService.GetTeams()
      .subscribe(
        (teams: Teams[]) => {

          if (teams != undefined && teams.length > 0) {
            this.teamsList = teams;

            teams = teams.filter(x => x.Active === true);
            teams.forEach((item1) => {
              this.teamsList.forEach((item, index) => {
                if (item1.TeamName === item.TeamName) {
                  let value = {
                    label: item1.TeamName,
                    value: index,

                  }
                  this.teamsListSelectItem.push(value)
                }
              });
            });

          }
        },
        (error: any) => {
          alert(error);
        })
  }

  buildSessionPageForm(programData) {
    const page = programData;
    this.SessionForm = this.formBuilder.group({
      IsSActive: [page ? page.IsSActive : false],
      SName: [page ? page.SName : '', Validators.required],
      SStartTime: [page ? page.SStartTime : '', Validators.required],
      SEndTime: [page ? page.SEndTime : '', Validators.required],
      SessionsId: [page ? page.SessionsId : ''],
      RoomName: [page ? page.RoomName : ''],
      Day: [page ? page.Day : ''],
      ProgramName: [page ? page.ProgramName : ''],
      RoomId: [page ? page.RoomId : '', Validators.required],
      ProgramId: [page ? page.ProgramId : '', Validators.required],
      Moderators: [page ? page.Moderators : []],
      Speakers: [page ? page.Speakers : []],
      Teams: [page ? page.Teams : []],
      SShortDescription: [page ? page.SShortDescription : []],
      SDescription: [page ? page.SDescription : []],
      SessionTypeId: [page ? page.SessionTypeId : []],
    });
  }

  findSelectedItemIndex(item): number {
    return this.sessions.indexOf(item);
  }

  findSelectedViewQuestionItemIndex(item): number {
    return this.sessionQuestions.indexOf(item);
  }

  toggleAddSessionForm() {
    this.radioButtonSelected = "";
    this.editItemSession = null;
    this.SessionForm = this.formBuilder.group({
      IsSActive: [false],
      SName: ['', Validators.required],
      SStartTime: ['', Validators.required],
      SEndTime: ['', Validators.required],
      SessionsId: [''],
      RoomName: [''],
      ProgramName: [''],
      RoomId: null,
      ProgramId: null,
      Moderators: [],
      Speakers: [],
      Teams: [],
      Day: null,
      SShortDescription: [],
      SDescription: [],
      SessionTypeId: []
    });
    this.addSessionFormStatus = true;
    this.SelectedModerate = [];
    this.SelectedSpeaker = [];
    this.SelectedTeams = [];
  }

  editSessionForm(eventData) {
    // this.LoadSessionsByRoomId(eventData.RoomId);
    this.editItemSession = eventData;
    this.editItemSession.SStartTime = new Date(this.editItemSession.SStartTime);
    this.editItemSession.SEndTime = new Date(this.editItemSession.SEndTime);

    if (eventData.Moderators != undefined) {
      this.SelectedModerate = eventData.Moderators;
    }
    if (eventData.Speakers != undefined) {
      this.SelectedSpeaker = eventData.Speakers;
      if (eventData.Speakers.length > 0) {
        this.showSpeakers = true;
        this.radioButtonSelected = "Speakers";
        this.showTeams = false;
      }
    }

    if (eventData.SessionTypeId != null && eventData.SessionTypeId != undefined) {
      this.selectedSessionType = eventData.SessionTypeId;
    }
    if (eventData.Teams != undefined) {
      this.SelectedTeams = eventData.Teams;
      if (eventData.Teams.length > 0) {
        this.showTeams = true;
        this.showSpeakers = false;
        this.radioButtonSelected = "Teams";
      }
    }

    let program = this.programList.filter(x => x.ProgramId == eventData.ProgramId)[0];
    if (program != undefined && program != null) {
      this.LoadDaysDropDown(program.EventId);
    }

    // this.editItemProgram.Event = null;
    this.addSessionFormStatus = true;
    if (this.addSessionFormStatus) {
      this.buildSessionPageForm(this.editItemSession)
    }

  }


  deleteSessionForm(session) {
    let index = this.findSelectedItemIndex(session);
    if (index >= 0) {
      let filteredSession = [];
      if (this.sessionQuestions != undefined) {
        filteredSession = this.sessionQuestions.filter(x => x.RoomId === session.RoomId && x.ProgramId === session.ProgramId &&
          x.SessionId === session.SessionsId)

        if (filteredSession != null || filteredSession.length != 0) {
          let indexQuestion = this.findSelectedViewQuestionItemIndex(filteredSession[0]);
          if (indexQuestion >= 0) {
            this.sessionQuestions.splice(indexQuestion, 1);
            this.sessionService.SaveSessionsQuestionsSessions(this.sessionQuestions);
          }
        }
      }
      this.sessions.splice(index, 1);
      this.sessionService.SaveSessions(this.sessions);
      this.LoadSessions();
    }
  }




  onNewProgramSubmit(formData) {

    let eventDays = [];
debugger
if(formData.value.Day!=null){
  var dayData = this.dayListDropDown.filter(x=>x.value==formData.value.Day)[0];
  formData.value.DayName=dayData.label;
}
    if (formData.value.RoomId != null && formData.value.RoomId != undefined) {
      let room = this.roomsList.filter(x => x.Id === formData.value.RoomId);
      if (room != undefined) {
        formData.value.RoomName = room[0].RoomName;
      }
    }

    if (formData.value.ProgramId != null && formData.value.ProgramId != undefined) {
      let program = this.programList.filter(x => x.ProgramId === formData.value.ProgramId);
      if (program != undefined) {
        formData.value.ProgramName = program[0].Title;
      }
    }

    // if(this.selectedSessionType!=null && this.selectedSessionType!=undefined){
    //   formData.value.SessionTypeId = this.selectedSessionType;
    // }
    //formData.value.SessionTypeId = this.selectedSessionType;
    //#region Check whether speaker/teams are selected
    if (this.radioButtonSelected == "Speakers") {
      formData.value.Teams = [];
      if (this.SelectedSpeaker.length > 0) {
        formData.value.Speakers = this.SelectedSpeaker;
      }
      else {
        this.SelectedSpeaker = [];
      }
    }

    if (this.radioButtonSelected == "Teams") {
      this.SelectedSpeaker = [];
      if (this.SelectedTeams.length > 0) {
        formData.value.Teams = this.SelectedTeams;
      }
      else {
        this.SelectedTeams = [];
      }
    }
    //#endregion

    //#region Convert selected date to javascript date format
    if (formData.value.SStartTime != undefined && formData.value.SStartTime != "") {
      formData.value.SStartTime = new Date(formData.value.SStartTime).getTime();
      formData.value.SStartDisplayTime = this.timeNow(formData.value.SStartTime);
    }

    if (formData.value.SEndTime != undefined && formData.value.SEndTime != "") {
      formData.value.SEndTime = new Date(formData.value.SEndTime).getTime();
      formData.value.SEndDisplayTime = this.timeNow(formData.value.SEndTime);
    }
    //#endregion




    if (this.sessions && this.sessions.length > 0) {
      eventDays = [...this.sessions];
    }

    //update existing data
    if (this.editItemSession) {
      if (this.SelectedSpeaker != undefined && this.SelectedSpeaker.length > 0) {
        formData.value.Speakers = this.SelectedSpeaker;

      }
      else {
        this.editItemSession.Speakers = [];
      }
      if (this.SelectedModerate != undefined && this.SelectedModerate.length > 0) {
        formData.value.Moderators = this.SelectedModerate;

      }
      else {
        this.editItemSession.Moderators = [];
      }



      eventDays[this.findSelectedItemIndex(this.editItemSession)] = formData.value;
    } else {

      //push new
      if (this.SelectedSpeaker != undefined) {
        formData.value.Speakers = this.SelectedSpeaker;

      }
      else {
        formData.value.Speakers = [];
      }
      if (this.SelectedModerate != undefined) {
        formData.value.Moderators = this.SelectedModerate;

      }
      else {
        formData.value.Moderators = [];
      }
      eventDays.push(formData.value);
    }

    if (!this.sessions) {
      this.sessions = [];
    }
    let filteredSession = [];
    let valueIndex = eventDays.indexOf(formData.value);
    eventDays.forEach((item, index) => {
      if (index == valueIndex) {

        item.SessionsId = valueIndex;
      }
      item.SStartTime = new Date(item.SStartTime).getTime();
      item.SEndTime = new Date(item.SEndTime).getTime();
      item.SStartDisplayTime = this.timeNow(item.SStartTime);
      item.SEndDisplayTime = this.timeNow(item.SEndTime);
      let sessionQuestionObj = new SessionQuestions();
      sessionQuestionObj.ProgramId = item.ProgramId;
      sessionQuestionObj.RoomId = item.RoomId;
      sessionQuestionObj.SessionId = item.SessionsId;

      if (this.sessionQuestions != undefined) {
        filteredSession = this.sessionQuestions.filter(x => x.RoomId === item.RoomId && x.ProgramId === item.ProgramId && x.SessionId === item.SessionsId)
        if (filteredSession == null || filteredSession.length == 0) {

          this.sessionQuestions.push(sessionQuestionObj);
          this.sessionService.SaveSessionsQuestionsSessions(this.sessionQuestions);
        }
      }


    });
    debugger
    this.promise = this.sessionService.SaveSessions(eventDays);
    this.promise.then(_ => this.msgs.push({ severity: 'success', summary: 'Success Message', detail: 'Saved' }))
      .catch(err => this.msgs.push({ severity: 'error', summary: 'Error Message', detail: err }));
    this.toggleAddSessionForm['eventDays'] = eventDays;
    this.editItemSession = null;
    this.radioButtonSelected = "";
    this.toggleAddSessionForm();
    this.addSessionFormStatus = false;
    this.SelectedModerate = [];
    this.SelectedSpeaker = [];
    this.SelectedTeams = [];
  }



  RadioButtonChange(value: any) {

    if (value == "Speakers") {
      this.showSpeakers = true;
      this.showTeams = false;
    }

    if (value == "Teams") {
      this.showTeams = true;
      this.showSpeakers = false;
    }


  }

  timeNow(i) {
    var d = new Date(i),
      h = (d.getHours() < 10 ? '0' : '') + d.getHours(),
      m = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
    let time = h + ':' + m;
    return time;
  }

  DayOnChange() {
    debugger
    this.sessionsTable = this.sessions.filter(X => X.RoomId === this.roomId && X.ProgramId === this.programId && X.Day === this.day);
    if (this.sessions.length == 0) {
      this.msgs.push({ severity: 'error', summary: 'No Data Found' })
      this.LoadSessions();
    }

  }

  clearfilter() {
    this.roomId = null;
    this.programId = null;
    this.day = null;
    this.sessionsTable = [];
    this.LoadSessions();
  }


  GetUserQuestion() {
    this.sessionService.GeSessionsQuestionsSessions().subscribe((sessionQuestions: SessionQuestions[]) => {
      this.sessionQuestions = sessionQuestions;
      if (sessionQuestions == null) {
        this.sessionQuestions = [];
      }
    })

  }

  findSelectedUserQuestionItemIndex(item): number {
    let aa = this.sessionQuestions.indexOf(item);
    var indexOfStevie = this.sessionQuestions.findIndex(i => i.ProgramId === item.ProgramId && i.RoomId === item.RoomId && i.SessionId === item.SessionId);
    return indexOfStevie;
  }

  LoadUserQuestionDetail(roomId: any, programId: any, sessionId: any) {
    localStorage.removeItem("RoomId");
    localStorage.removeItem("ProgramId");
    localStorage.removeItem("SessionId");
    localStorage.setItem("RoomId", roomId);
    localStorage.setItem("ProgramId", programId);
    localStorage.setItem("SessionId", sessionId);

    window.open('/#/view-user-questions');

  }

  programListDropown(event: any) {
    debugger
    //let events = this.eventsList.filter(x=>x.EventId==event.value);
    let program = this.programList.filter(x => x.ProgramId == event.value)[0];
    if (program != undefined && program != null) {
      this.LoadDaysDropDown(program.EventId);
    }
  }

  LoadDaysDropDown(eventId){
    let event = this.eventsList.filter(x => x.EventId == eventId)[0];
    if (event != null) {
      let value = {
        label: "-- Select --",
        value: null,

      }
      let array: any[] = [];

      array.push({ label: "-- Select --", value: null });
      // array.push({ label: "Day 1", value: 1 });
      // array.push({ label: "Day 2", value: 2 });
      if (event.Days != null && event.Days != undefined) {
        event.Days.forEach((item1, index) => {
          array.push({ label: item1.Day, value: index });
        });

      }
      this.dayListDropDown = array;
      this.dayListDropDownFilter = array;
    }
  }
  programListDropDownChange(event) {
    debugger
    //let events = this.eventsList.filter(x=>x.EventId==event.value);
    let program = this.programList.filter(x => x.ProgramId == event.value)[0];
    if (program != undefined && program != null) {
      this.LoadDaysDropDown(program.EventId);
    }
  }
}


