import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
  providedIn: 'root'
})
export class TeamsService {

  baseTeamsPath:string = "/Teams/";
  constructor(private fireBase: AngularFireDatabase) { }

  GetTeams() {
    return this.fireBase.object(this.baseTeamsPath).valueChanges();
  }

  SaveTeams(data:any){
    return this.fireBase.object(this.baseTeamsPath).set(data);
  }

}
