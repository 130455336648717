import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SessionsService {
  basepathSessions: string = "/Sessions/";
  basepathUserQuestions: string = "/UserQuestions/";
  constructor(private fireBase: AngularFireDatabase) {

  }

  GetSessions() {
    return this.fireBase.object(this.basepathSessions).valueChanges();

  }

  SaveSessions(sessionObj: any) {
    return this.fireBase.object(this.basepathSessions).set(sessionObj);
  }

  SaveSessionsQuestionsSessions(sessionObj: any) {
    return this.fireBase.object(this.basepathUserQuestions).set(sessionObj);
  }

  GeSessionsQuestionsSessions() {
    return this.fireBase.object(this.basepathUserQuestions).valueChanges();
  }

  SaveUserQuestions(SessionQuestionId: any, userQuestion: any) {
    return this.fireBase.list(this.basepathUserQuestions + "/" + SessionQuestionId + "/Questions").push(userQuestion);
  }

  deleteUserQuestionsObject(sessionQuestionId: any, itemKey: any) {
    return this.fireBase.object(this.basepathUserQuestions + "/" + sessionQuestionId + '/Questions/' + itemKey).remove();
  }
}
