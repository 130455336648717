import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {
  baseTicketsPath:string = "/Tickets/";
  baseDeletedTicketsPath:string = "/DeletedTickets/";
  constructor(private fireBase: AngularFireDatabase) { }



  getTicketsList() {
    return this.fireBase.object(this.baseTicketsPath).valueChanges();
  }


  getDeletedTicketsList() {
    return this.fireBase.object(this.baseDeletedTicketsPath).valueChanges();
  }


  SaveDeletedTicketsList(data:any) {
    return this.fireBase.object(this.baseDeletedTicketsPath).set(data);
  }
}
