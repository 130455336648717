//import { FirebaseListObservable } from 'angularfire2/database-deprecated';
import { FileUpload } from './../Models/FileUpload';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import * as firebase from 'firebase';
import { GuidGenerator } from '../Models/GuidGenerator';
import { Observable } from 'rxjs';



@Injectable()
export class UploadFileServiceService {
  isDeleted: boolean = false;

  private basePath = '/uploads';
  fileUploads: Observable<FileUpload[]>;

  constructor(private db: AngularFireDatabase) { }

  pushFileToStorage(fileUpload: FileUpload, progress: { percentage: number }) {
    const storageRef = firebase.storage().ref();
    const uniqueGUID = GuidGenerator.GenerateGuid();
    let file = fileUpload.file.name.split('.');

    let filename = file[0] + uniqueGUID + "." + file[1];
    const uploadTask = storageRef.child(`${this.basePath}/${filename}`).put(fileUpload.file);
   // const uploadTask = storageRef.child(`${this.basePath}/${fileUpload.file.name}`).put(fileUpload.file);

    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        // in progress


      },
      (error) => {
        // fail

        console.log(error)

      },
      () => {
        // success
        const snap = uploadTask.snapshot as firebase.storage.UploadTaskSnapshot
        progress.percentage = Math.round((snap.bytesTransferred / snap.totalBytes) * 100)
        fileUpload.url = uploadTask.snapshot.downloadURL
        fileUpload.name = fileUpload.file.name
        //this.saveFileData(fileUpload)

      }
    );


  }

  private saveFileData(fileUpload: FileUpload) {
    this.db.list(`${this.basePath}/`).push(fileUpload)
  }

  // getFileUploads(query = {}) {
  //   this.fileUploads = this.db.list(this.basePath, {
  //     query: query
  //   });
  //   return this.fileUploads
  // }

  deleteFileUpload(fileUpload: FileUpload) {
    this.deleteFileDatabase(fileUpload.$key)
      .then(() => {
        this.deleteFileStorage(fileUpload.name)
      })
      .catch(error => console.log(error))
  }

  private deleteFileDatabase(key: string) {
    return this.db.list(`${this.basePath}/`).remove(key)
  }

  private deleteFileStorage(name: string) {
    const storageRef = firebase.storage().ref()
    storageRef.child(`${this.basePath}/${name}`).delete()
  }


  removeFireBaseImage(url): boolean {
    var desertRef = firebase.storage().refFromURL(url)

    // Delete the file
    desertRef.delete().then(function () {
      this.isDeleted = true;
    }).catch(function (error) {
      this.isDeleted = false;
    });
    return this.isDeleted;
  }

  
}
