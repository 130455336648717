import { Injectable } from '@angular/core';
import { IProfile } from '../Components/profiles/profiles';
import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
  providedIn: 'root'
})
export class ProfilesService {

  baseProfilesPath:string = "/Profiles/";
  constructor(private fireBase: AngularFireDatabase) { }



  getProfilesList() {
    return this.fireBase.object(this.baseProfilesPath).valueChanges();
  }

  createProfile(data:any){
    return this.fireBase.object(this.baseProfilesPath).set(data);
  }
}
