import { Component, OnInit } from '@angular/core';
import { Rooms } from '../rooms/rooms';
import { Program, Events } from '../events/events';
import { Message } from 'primeng/api';
import { QuestionsService } from 'src/app/Services/questions.service';
import { RoomsservicesService } from 'src/app/Services/roomsservices.service';
import { EventsService } from 'src/app/Services/events.service';
import { FormBuilder, Validators } from '@angular/forms';
import { IQuestions, Questions, VotingQUestions } from './questions';
import { SessionsService } from 'src/app/Services/sessions.service';

import { Sessions } from '../sessions/sessions';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent implements OnInit {

  promise: Promise<void>
  roomsList: Rooms[] = [];
  roomsListDropDown: any[] = [];
  programList: Program[] = [];
  programListDropDown: any[] = [];
  sessionList: Sessions[] = [];
  sessionListDropDown: any[] = [];

  typeDropDown: any[] = [];
  msgs: Message[] = [];
  isNewQuestion: boolean;
  editItemQuestion: any;
  QuestionForm: any;
  addQuestionFormStatus: boolean;
  votingsQuestions: VotingQUestions[] = [];
  votingsQuestionsData;

  programId: any;
  roomId: any;
  SessionId: any;
  //for answers
  displayDialog: boolean;
  question: Questions;
  selectedQuestion: any;
  newQuestion: boolean;
  questions: Questions[];
  cols: any[];

  answer: any;
  selectedAnswer: any;
  newAnswer: boolean;
  answers: any[];
  displayDialogAnswer: boolean;
  eventsList: Events[];
  dayListDropDown: any[] = [];
  dayListDropDownFilter: any[] = [];
  constructor(private questionService: QuestionsService, private roomsService: RoomsservicesService,
    private eventService: EventsService, private sessionService: SessionsService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    // this.LoadRooms();
    // this.LoadTypeDropDown();
    // this.LoadVotingQuestions();
    this.LoadRooms();
    this.LoadTypeDropDown()
    this.LoadVotingQuestions();

    this.LoadEvents();
    this.answers = [];
  }
  LoadTypeDropDown() {
    this.typeDropDown = [];

    let value = {
      label: "-- Select --",
      value: null,

    }
    this.typeDropDown.push(value)
    this.questionService.GetTypes()
      .subscribe(
        (types: any[]) => {
          types.forEach((item1, index) => {
            let value = {
              label: item1,
              value: index,
            }
            this.typeDropDown.push(value)
          },
            (error: any) => {
              alert(error);
            })
        })
  }


  LoadRooms() {

    this.roomsListDropDown = [];

    let value = {
      label: "-- Select --",
      value: null,

    }
    this.roomsListDropDown.push(value)
    this.roomsService.GetAllRooms()
      .subscribe(
        (room: Rooms[]) => {

          if (room != undefined && room.length > 0) {
            this.roomsList = room;

            room = room.filter(x => x.IsRoomActive === true);
            room.forEach((item1) => {
              this.roomsList.forEach((item, index) => {
                if (item1.RoomName === item.RoomName) {
                  let value = {
                    label: item1.RoomName,
                    value: index,

                  }
                  this.roomsListDropDown.push(value)
                }
              });
            });
            this.LoadPrograms();
          }

        },
        (error: any) => {
          alert(error);
        })
  }

  LoadPrograms() {

    this.programListDropDown = [];
    let value = {
      label: "-- Select --",
      value: null,

    }
    this.programListDropDown.push(value)
    this.eventService.GetPrograms()
      .subscribe(
        (programs: Program[]) => {

          if (programs != undefined && programs.length > 0) {
            this.programList = programs;

            programs = programs.filter(x => x.Active === true);
            programs.forEach((item1) => {
              this.programList.forEach((item, index) => {
                if (item1.Title === item.Title) {
                  let value = {
                    label: item1.Title,
                    value: index,

                  }
                  this.programListDropDown.push(value)
                }
              });
            });

          }

        },
        (error: any) => {
          alert(error);
        })
  }



  LoadSessions(programId: any, roomId: any) {
    this.sessionListDropDown = [];
    let value = {
      label: "-- Select --",
      value: null,

    }
    this.sessionListDropDown.push(value)
    this.sessionService.GetSessions()
      .subscribe(
        (sessions: Sessions[]) => {

          if (sessions != undefined && sessions.length > 0) {
            this.sessionList = sessions;

            sessions = sessions.filter(x => x.IsSActive === true && x.ProgramId === programId && x.RoomId == roomId);
            sessions.forEach((item1) => {
              this.sessionList.forEach((item, index) => {
                if (item1.SessionsId === item.SessionsId) {
                  let value = {
                    label: item1.SName,
                    value: index,

                  }
                  this.sessionListDropDown.push(value)
                }
              });
            });

          }

        },
        (error: any) => {
          alert(error);
        })
  }


  LoadVotingQuestions() {
    this.questionService.GetQuestions()
      .subscribe(
        (votingQuestion: VotingQUestions[]) => {
          this.votingsQuestions = votingQuestion;
        },
        (error: any) => {
          alert(error);
        })
  }

  RoomOnChange() {
    this.roomId = this.QuestionForm.value.RoomId;
    this.programId = this.QuestionForm.value.ProgramId;
    this.LoadSessions(this.programId, this.roomId);
  }



  buildPageForm(data) {
    const page = data;
    this.QuestionForm = this.formBuilder.group({
      Questions: [page ? page.Questions : []],
      RoomId: [page ? page.RoomId : '', Validators.required],
      ProgramId: [page ? page.ProgramId : '', Validators.required],
      SessionId: [page ? page.SessionId : '', Validators.required],
      SessionStartTime: [page ? page.SessionStartTime : ''],
      SessionEndTime: [page ? page.SessionEndTime : ''],
      Active: [page ? page.Active : false],
      Day: [page ? page.Day : '', Validators.required],
    });
  }



  findSelectedItemIndex(item): number {
    return this.votingsQuestions.indexOf(item);
  }


  onNewQuestionSubmit(formData) {
    let eventDays = [];
    if (formData.value.RoomId != null && formData.value.RoomId != undefined) {
      let room = this.roomsList.filter(x => x.Id === formData.value.RoomId);
      if (room != undefined) {
        formData.value.RoomName = room[0].RoomName;
      }
    }
    if (formData.value.ProgramId != null && formData.value.ProgramId != undefined) {
      let program = this.programList.filter(x => x.ProgramId === formData.value.ProgramId);
      if (program != undefined) {
        formData.value.ProgramName = program[0].Title;
      }
    }
    if (formData.value.Day != null && formData.value.Day != undefined) {
      let day = this.dayListDropDown.filter(x => x.value === formData.value.Day);
      if (day != undefined) {
        formData.value.DayText = day[0].label;
      }
    }
    if (formData.value.SessionId != null && formData.value.SessionId != undefined) {
      let session = this.sessionList.filter(x => x.SessionsId === formData.value.SessionId);
      if (session != undefined) {
        formData.value.SessionName = session[0].SName;
        formData.value.SStartDisplayTime = this.timeNow(session[0].SStartTime);
        formData.value.SEndDisplayTime = this.timeNow(session[0].SEndTime);
      }
    }

    if (this.votingsQuestions && this.votingsQuestions.length > 0) {
      eventDays = [...this.votingsQuestions];
    }
    if (this.editItemQuestion) {
      formData.value.Questions = this.questions;
      eventDays[this.findSelectedItemIndex(this.editItemQuestion)] = formData.value;
    } else {
      formData.value.Questions = this.questions;
      eventDays.push(formData.value);
    }

    if (!this.votingsQuestions) {
      this.votingsQuestions = [];
    }


    let valueIndex = eventDays.indexOf(formData.value);
    eventDays.forEach((item, index) => {
      if (index == valueIndex) {

        item.VotingId = valueIndex;
      }
    })
    this.promise = this.questionService.SaveQuestions(eventDays);
    this.promise.then(_ => this.msgs.push({ severity: 'success', summary: 'Success Message', detail: 'Saved' }))
      .catch(err => this.msgs.push({ severity: 'error', summary: 'Error Message', detail: err }));
    this.toggleAddQuestionForm['eventDays'] = eventDays;
    this.editItemQuestion = null;
    this.toggleAddQuestionForm();
    this.addQuestionFormStatus = false;
    this.question = new Questions();
    this.questions = [];
    this.newQuestion = false;
    this.displayDialog = false;
  }

  toggleAddQuestionForm() {
    this.isNewQuestion = true;
    this.editItemQuestion = null;
    this.QuestionForm = this.formBuilder.group({
      Question: [],
      RoomId: [null, Validators.required],
      ProgramId: [null, Validators.required],
      SessionId: [null, Validators.required],
      SessionStartTime: [''],
      SessionEndTime: [''],
      Active: false,
      Day: [null, Validators.required]
    });
    this.addQuestionFormStatus = true;
    this.newQuestion = true;
    this.question = new Questions();
    this.questions = [];
  }



  editQuestionForm(profileData) {
    this.LoadSessions(profileData.ProgramId, profileData.RoomId);
    setTimeout(() => {
      this.isNewQuestion = false;
      this.editItemQuestion = profileData;
      this.addQuestionFormStatus = true;
      let program = this.programList.filter(x => x.ProgramId == profileData.ProgramId)[0];
      if (program != undefined && program != null) {
        this.LoadDaysDropDown(program.EventId);
      }
      if (profileData.Questions != undefined) {
        this.questions = profileData.Questions;
      }
      if (this.addQuestionFormStatus) {
        this.buildPageForm(this.editItemQuestion)
      }
    }, 1000)


  }


  deleteQuestionForm(question) {
    let index = this.findSelectedItemIndex(question);
    if (index >= 0) {
      this.votingsQuestions.splice(index, 1);
      this.questionService.SaveQuestions(this.votingsQuestions);
    }
  }


  showDialogToAdd() {
    this.newQuestion = true;
    this.question = new Questions();
    this.question.IsActive = false;
    this.question.IsCompleted = false;
    this.question.Answers = [];
    this.displayDialog = true;
  }

  onRowSelect(event) {
    this.newQuestion = false;
    this.question = new Questions();
    this.question = event.data;
    this.answers = event.data.Answers;
    this.question = this.cloneCar(this.question);
    this.displayDialog = true;
  }

  cloneCar(c: any): any {
    let car = {};
    for (let prop in c) {
      car[prop] = c[prop];
    }
    return car;
  }




  save() {
    let questions = [...this.questions];
    if (this.newQuestion) {
      if (this.question.SubbmitedAnswers == undefined) {
        this.question.SubbmitedAnswers = [];
        for (let i = 0; i < this.question.Answers.length; i++) {
          let j = "0";
          this.question.SubbmitedAnswers.push(0);
        }

      }
      if (this.question.UsersId == undefined) {
        this.question.UsersId = " ";
      }
      if (this.question.IsCompleted == undefined) {
        this.question.IsCompleted = false;
      }
      this.question.IsActive = false;
      questions.push(this.question);
    }
    else {
      if (this.question.SubbmitedAnswers == undefined) {
        this.question.SubbmitedAnswers = [];
        for (let i = 0; i < this.question.Answers.length; i++) {
          let j = "0";
          this.question.SubbmitedAnswers.push(0);
        }
      }
      if (this.question.UsersId == undefined) {
        this.question.UsersId = " ";
      }
      if (this.question.IsCompleted == undefined) {
        this.question.IsCompleted = false;
      }
      questions[this.questions.indexOf(this.selectedQuestion)] = this.question;

    }
    let valueIndex = questions.indexOf(this.question);
    questions.forEach((item, index) => {
      if (index == valueIndex) {
        item.QuestionId = valueIndex;
      }
    })
    this.questions = questions;
    this.question = null;
    this.displayDialog = false;
  }

  delete() {
    let index = this.questions.indexOf(this.selectedQuestion);
    this.questions = this.questions.filter((val, i) => i != index);
    this.question = null;
    this.displayDialog = false;
  }

  showDialogToAddAnswer() {
    this.newAnswer = true;
    this.answer = {}
    this.displayDialogAnswer = true;
  }

  onRowSelectAnswer(event) {
    this.newAnswer = false;
    this.answer = {};
    let aa = [];
    aa.push({ Answer: event.data });
    this.answer = aa[0];
    this.answer = this.cloneCar(this.answer);
    this.displayDialogAnswer = true;
  }

  saveAnswer() {
    let answers = [...this.answers];
    if (this.newAnswer) {
      answers.push(this.answer.Answer);
      this.question.Answers.push(this.answer.Answer);
    }
    else {
      answers[this.answers.indexOf(this.selectedAnswer)] = this.answer.Answer;
      this.question.Answers = answers;
    }
    this.answers = answers;
    this.answer = null;
    this.displayDialogAnswer = false;
  }

  deleteAnswer() {
    let index = this.question.Answers.indexOf(this.selectedAnswer);
    this.question.Answers = this.question.Answers.filter((val, i) => i != index);
    this.answer = null;
    this.displayDialogAnswer = false;
  }
  timeNow(i) {
    var d = new Date(i),
      h = (d.getHours() < 10 ? '0' : '') + d.getHours(),
      m = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
    let time = h + ':' + m;
    return time;
  }

  programListDropown(event: any) {

    //let events = this.eventsList.filter(x=>x.EventId==event.value);
    let program = this.programList.filter(x => x.ProgramId == event.value)[0];
    if (program != undefined && program != null) {
      this.LoadDaysDropDown(program.EventId);
    }
  }

  LoadDaysDropDown(eventId) {
    let array: any[] = [];
    let value = {
      label: "-- Select --",
      value: null
    }
    array.push(value)
    let event = this.eventsList.filter(x => x.EventId == eventId)[0];
    if (event != null) {
      if (event.Days != null && event.Days != undefined) {
        event.Days.forEach((item1, index) => {
          array.push({ label: item1.Day, value: index });
        });
      }
      this.dayListDropDown = array;
    }
  }

  LoadEvents() {
    this.eventService.GetEvents()
      .subscribe(
        (events: Events[]) => {

          this.eventsList = events;
        },
        (error: any) => {
          alert(error);
        })
  }
}
