import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
  providedIn: 'root'
})
export class PhotoGalleryService {
  
  basePhotoGalleryPath:string = "/PhotoGallery/";
  constructor(private fireBase: AngularFireDatabase) { }

 deletePhotoGallery(key:string){
  return this.fireBase.object(this.basePhotoGalleryPath + key).remove();
 }

  getPhotoGalleryList() {
    return this.fireBase.object(this.basePhotoGalleryPath).valueChanges();
  }

  savePhotoGallery(data:any){
    return this.fireBase.object(this.basePhotoGalleryPath).set(data);
  }

  getPhotoGalleryDataByKey($key:string){
    return this.fireBase.object(this.basePhotoGalleryPath + $key).valueChanges();
  }

  updatePhotoGallery(data:any,$key:any){
    return this.fireBase.object(this.basePhotoGalleryPath + $key).update(data);
  }

  getPhotoGalleryByKey($key:any){
    debugger
    return this.fireBase.object(this.basePhotoGalleryPath + $key).valueChanges();
  }
}
