import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import {  AngularFireDatabase } from 'angularfire2/database';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { fcmModel } from '../Models/fcmmodel';

@Injectable({
  providedIn: 'root'
})
export class FcmtokensService {
  baseEventsPath: string = "/fcmtokens/";
  constructor(private fireBase: AngularFireDatabase, private http: HttpClient) { }

  Getfcmtokens() {
    return this.fireBase.object(this.baseEventsPath).valueChanges();
  }

  SendPushNotification(fcmObject: fcmModel) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Key=" + environment.firebasePushnotificationAuthorizationKey
    });
    let options = { headers: headers };
    return this.http.post( environment.firebaseFCmApi, fcmObject, options);



  }
}
