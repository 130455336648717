export class Notification {
    title: string;
    body: string;
    sound:string;
    badge:number;
    icon:string;
    isScheduled :boolean;
    scheduledTime:string;
   
}

export class fcmModel {
    notification: Notification;
    to: string;
    
   
}