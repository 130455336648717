import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { fcmModel, Notification } from 'src/app/Models/fcmmodel';
import { FcmtokensService } from 'src/app/Services/fcmtokens.service';
import { UsernotificationService } from 'src/app/Services/usernotification.service';
import { UserNotification } from 'src/app/Models/UserNotification';
import { GuidGenerator, BSGEHelper } from 'src/app/Models/GuidGenerator';
import { FcmToken } from 'src/app/Models/fcmtoken';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-push-notification',
  templateUrl: './push-notification.component.html',
  styleUrls: ['./push-notification.component.css']
})
export class PushNotificationComponent implements OnInit {
  msgs: Message[] = [];
  NotificationForm: FormGroup;
  loading: boolean = false;
  fcmTokenList: any[];
  userNotificationsList: any[];
  userNotificationsList1: any[];
  promise: Promise<void>;
  fcmToken: FcmToken = new FcmToken();
  fcmModel: fcmModel = new fcmModel();
  UserNotification: UserNotification = new UserNotification();
  failedTokens: any[];
  showSpinner: boolean = false;
  constructor(private formBuilder: FormBuilder, private fcmtokenservice: FcmtokensService, private userNotificationService: UsernotificationService) { }

  ngOnInit() {
    this.LoadFcmTokens();
    this.LoadUserNotifications();
    this.buildFormGroup();
  }

  buildFormGroup() {

    this.NotificationForm = this.formBuilder.group({
      NotificationTitle: ['', Validators.required],
      NotificationBody: ['', Validators.required],

    });
    this.loading = true;
  }

  SendNotification(formData) {
    this.showSpinner = true;
    let isSuccess = false;
    if (this.userNotificationsList == null && this.userNotificationsList == undefined) {
      this.userNotificationsList = [];
    }
    if (this.fcmTokenList != undefined && this.fcmTokenList != null) {
      this.fcmTokenList.forEach(x => {
        let badge = 1;
        let userNotification = this.userNotificationsList != null ? this.userNotificationsList.filter(y => y.deviceid == x.deviceid && y.IsRead == false) : undefined;
        if (userNotification != undefined) {
          badge = userNotification.length + 1;
        }
        this.fcmModel = new fcmModel();
        this.fcmModel.to = x.deviceid;
        this.fcmModel.notification = new Notification();
        this.fcmModel.notification.title = formData.value.NotificationTitle;
        this.fcmModel.notification.body = formData.value.NotificationBody;        
        this.fcmModel.notification.sound = "default";
        this.fcmModel.notification.badge = badge;
        this.fcmtokenservice.SendPushNotification(this.fcmModel).subscribe(res => {
          this.UserNotification = new UserNotification();

          this.UserNotification.Id = GuidGenerator.GenerateGuid();
          this.UserNotification.IsRead = false;
          this.UserNotification.NotificationBody = formData.value.NotificationBody;
          this.UserNotification.Title = formData.value.NotificationTitle;
          this.UserNotification.Date =BSGEHelper.GetDate(new Date()) 
          this.UserNotification.UserId = x.userid;
          this.UserNotification.deviceid = x.deviceid;
          this.userNotificationsList.push(this.UserNotification);
          this.userNotificationService.saveUserNotification(this.userNotificationsList)
          isSuccess=true;
        },
          err => {
            console.log(err.message);
            isSuccess = false;
            this.msgs.push({ severity: 'error', summary: 'Error Message', detail: 'failed to send the notification' })
            this.failedTokens.push({ error: err.message, tokenid: x });
          });
        ;
      })
      setTimeout(()=>{ 
      if(isSuccess){
        this.showSpinner = false;
        this.msgs.push({ severity: 'success', summary: 'Success Message', detail: 'Push Notifications sent' })
        this.buildFormGroup();
      }}, 5000);
    }
    else {
      this.msgs.push({ severity: 'error', summary: 'Error Message', detail: 'No Devices Found' })
    }

  }

  LoadUserNotifications() {
    this.userNotificationService.getUserNotificationList()
      .subscribe(
        (userNotificationsList: UserNotification[]) => {
          this.userNotificationsList = userNotificationsList;

        },
        (error: any) => {
          alert(error);
        });

  }

  LoadFcmTokens() {
    this.fcmtokenservice.Getfcmtokens()
      .subscribe(
        (fcmTokenList: FcmToken[]) => {
          this.fcmTokenList = [];
          for (let key in fcmTokenList) {
            this.fcmToken = new FcmToken();
            this.fcmToken = fcmTokenList[key];
            this.fcmToken.$key = key;
            this.fcmTokenList.push(this.fcmToken);
          }
        },
        (error: any) => {
          alert(error);
        });
  }
}
