export interface IEvents {
    EventTitle:string;
    ShortDescription:string;
    Image:string;
    Description:string;
    StartDate:string;
    EndDate:string;
    Active:boolean;
    EventId:number;
    Days:Days[];
    IsCustomEventSelected:boolean;
    CustomEventId:string[];
  }

  export class Events implements IEvents {    
    EventTitle:string;
    ShortDescription:string;
    Image:string;
    Description:string;
    StartDate:string;
    EndDate:string;
    Active:boolean;
    EventId:number;
    Days:Days[];
    IsCustomEventSelected:boolean;
    CustomEventId:string[];
  }

  export interface IProgram{
    Event:IEvents;
    EventId:number;
    Title:string;
    Description:string;
    Image:string; 
    Active:boolean;
    ProgramId:number;
    ProgramUrl:string;
    
  }


  export class Program implements IProgram{
    Event:IEvents;
    EventId: number;   
    Title: string;
    Description: string;
    Image: string;
    Active:boolean;
    ProgramId:number;
    ProgramUrl:string;

  }

  export interface IDays {
    Date:Date;
    Day:string;
    DayId:number;
    ConvertedDate:string;
  }

  export class Days implements IDays {    
    Date:Date;
    Day:string;
    DayId:number;
    ConvertedDate:string;
  }