export interface IUserNotification {
    Id:string;
    Title: string;
    NotificationBody: string;
    IsRead:boolean;
    UserId:number;
    deviceid:string;
    Date:string;
}

export class UserNotification implements IUserNotification{
    Id:string;
    Title: string;
    NotificationBody: string;
    IsRead:boolean;
    UserId:number;
    deviceid:string;
    Date:string;
}
