export interface IPhotoGallery {
    ImageUrl: string
    ImageName: string
    ImageExtension: string
    UploadedBy: string
    IsValid: boolean
    UploadedDate: string
    key: string;
    IsPending:boolean;
}

export class PhotoGallery implements IPhotoGallery {
    ImageUrl: string
    ImageName: string
    ImageExtension: string
    UploadedBy: string
    IsValid: boolean
    UploadedDate: string
    key: string;
    IsPending:boolean;

}