import { Component, OnInit } from "@angular/core";
import { FileUpload } from "./../../Models/FileUpload";
import { Message } from "primeng/api";
import { SessionType } from './sessiontype';
import { UploadFileServiceService } from "src/app/Services/upload-file-service.service";
import { FormBuilder, Validators } from "@angular/forms";
import { SessionTypeService } from 'src/app/Services/session-type.service';

@Component({
    selector: "app-session-type",
    templateUrl: "./session-type.component.html",
    styleUrls: ["./session-type.component.css"]
})
export class SessionTypeComponent implements OnInit {
    promise: Promise<void>;
    sessionTypes: SessionType[] = [];
    selectedFiles: any;
    sessionTypeData;
    currentFileUpload: FileUpload;
    progress: { percentage: number } = { percentage: 0 };
    msgs: Message[] = [];
    isNewSessionType: boolean;
    editSessionType: any;
    SessionTypeForm: any;
    addSessionTypeFormStatus: boolean;

    constructor(
        private sessionTypeService: SessionTypeService,
        private fileUploadService: UploadFileServiceService,
        private formBuilder: FormBuilder
    ) {}

    ngOnInit() {
        this.LoadSessionTypes();
    }

    LoadSessionTypes() {
        this.sessionTypeService.GetAllSessionTypes().subscribe(
            (sessionTypes: SessionType[]) => {
                this.sessionTypes = sessionTypes;
                this.buildPageForm(this.sessionTypes);
            },
            (error: any) => {
                alert(error);
            }
        );
    }

    buildPageForm(sessionTypes) {
        const page = sessionTypes;
        this.SessionTypeForm = this.formBuilder.group({
            Name: [page ? page.Name : "", Validators.required],
            Icon: [page ? page.Icon : ""]
        });
    }



    
  findSelectedItemIndex(item): number {
    return this.sessionTypes.indexOf(item);
  }


  onNewSessionTypeSubmit(formData) {
    let sessionsData = [];
    
    if (this.currentFileUpload != null && this.currentFileUpload.url != null) {
      if (formData.value.Icon != null && formData.value.Icon != "") {
        this.fileUploadService.removeFireBaseImage(formData.value.Icon);
        formData.value.Icon = '';
      }
      debugger
      formData.value.Icon = this.currentFileUpload.url;

    }
    if (this.sessionTypes && this.sessionTypes.length > 0) {
      sessionsData = [...this.sessionTypes];
    }
    if (this.editSessionType) {
      sessionsData[this.findSelectedItemIndex(this.editSessionType)] = formData.value;
    } else {
      sessionsData.push(formData.value);
    }

    if (!this.sessionTypes) {
      this.sessionTypes = [];
    }

    let valueIndex = sessionsData.indexOf(formData.value);
    sessionsData.forEach((item, index) => {

      if (index == valueIndex) {
        item.SessionTypeId = valueIndex;
      }
    });

    this.promise = this.sessionTypeService.SaveSessionType(sessionsData);
    this.promise.then(_ => this.msgs.push({ severity: 'success', summary: 'Success Message', detail: 'Saved' }))
      .catch(err => this.msgs.push({ severity: 'error', summary: 'Error Message', detail: err }));
    this.toggleAddSessionTypeForm['sessionsData'] = sessionsData;
    this.editSessionType = null;
    this.toggleAddSessionTypeForm();
    this.progress.percentage = 0;
    this.addSessionTypeFormStatus = false;
    this.currentFileUpload=null;
  }

  toggleAddSessionTypeForm () {
    this.isNewSessionType = true;
    this.editSessionType = null;
    this.SessionTypeForm = this.formBuilder.group({
      Name: ['', Validators.required],
      Icon: ''
    
    });
    this.addSessionTypeFormStatus = true;

  }



  editSessionTypeForm (profileData) {
      debugger
    this.isNewSessionType = false;
    // if(profileData!=undefined && profileData.Password !="" && profileData.Password!=undefined){
    //   profileData.Password= this.decryptData(profileData.Password);

    // }

    this.editSessionType = profileData;    
    this.addSessionTypeFormStatus = true;
    if (this.addSessionTypeFormStatus) {
      this.buildPageForm(this.editSessionType)
    }
    this.progress.percentage = 0;
  }


  deleteSessionTypeForm(profile) {
    debugger
    let index = this.findSelectedItemIndex(profile);
    if (index >= 0) {
      this.sessionTypes.splice(index, 1);
      this.sessionTypeService.SaveSessionType(this.sessionTypes);
    }
  }



  //#region  Helpers
  //Upload Image
  ////Image Upload
  selectFile(event) {
    const file = event.target.files.item(0);
    if (file.type.match('image.*')) {
      this.selectedFiles = event.target.files;
      this.upload();
    } else {
      alert('invalid format!');
    }

  }

  upload() {

    const file = this.selectedFiles.item(0)
    this.selectedFiles = undefined
    this.currentFileUpload = new FileUpload(file);
    this.fileUploadService.pushFileToStorage(this.currentFileUpload, this.progress)

  }
}
