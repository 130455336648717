import { Component, OnInit } from '@angular/core';
import { Profile } from '../profiles/profiles';
import { TeamsService } from 'src/app/Services/teams.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Teams } from './teams';
import { ProfilesService } from 'src/app/Services/profiles.service';
import { SelectItem, Message } from 'primeng/api';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.css']
})
export class TeamsComponent implements OnInit {
  promise: Promise<void>
  SelectedSpeaker: Profile[];
  speakerList: SelectItem[] = []; isNewEdit: boolean;
  editItemTeams: any;
  addTeamsFormStatus: boolean;
  teamId: any;
  msgs: Message[] = [];

  teams: Teams[] = [];
  TeamsForm: any;
  profileList: Profile[];
  constructor(private teamsService: TeamsService, private formBuilder: FormBuilder, private profileService: ProfilesService) { }

  ngOnInit() {
    this.LoadTeams();
    this.LoadSpeakers();
  }


  LoadTeams() {
    this.teamsService.GetTeams()
      .subscribe(
        (teams: Teams[]) => {
          this.teams = teams;
          this.buildPageForm(this.teams);
        },
        (error: any) => {
          alert(error);
        }
      );

  }


  LoadSpeakers() {
    this.profileService.getProfilesList()
      .subscribe(
        (profile: Profile[]) => {
          this.profileList = profile;
          profile = profile.filter(x => x.Active === true);
          profile.forEach((item1) => {
            this.profileList.forEach((item, index) => {
              if (item1.Forename === item.Forename) {
                let value = {
                  label: item1.Forename,
                  value: index,

                }
                this.speakerList.push(value);

              }
            });
          });

        },
        (error: any) => {
          alert(error);
        })
  }

  buildPageForm(teamsData) {
    const page = teamsData;
    this.TeamsForm = this.formBuilder.group({
      TeamId: [page ? page.TeamId : ''],
      TeamName: [page ? page.TeamName : '', Validators.required],
      Description: [page ? page.Description : ''],
      Speakers: [page ? page.Speakers : []],
      Active: [page ? page.Active : false]
    });
  }


  findSelectedItemIndex(item): number {
    return this.teams.indexOf(item);
  }

  toggleAddTeamsForm() {
    this.isNewEdit = true;
    this.editItemTeams = null;
    this.TeamsForm = this.formBuilder.group({
      TeamId: '',
      TeamName: ['', Validators.required],
      Description: '',
      Speakers: [],
      Active: false
    });
    this.addTeamsFormStatus = true;
    this.SelectedSpeaker = [];
  }



  editTeamsForm(eventData: any, teamId: any) {
    this.isNewEdit = false;
    this.teamId = teamId;
    this.editItemTeams = eventData;
    this.addTeamsFormStatus = true;
    if (eventData.Speakers != undefined) {
      this.SelectedSpeaker = eventData.Speakers;
    }
    if (this.addTeamsFormStatus) {
      this.buildPageForm(this.editItemTeams)
    }

  }


  deleteTeamForm(team) {
    let index = this.findSelectedItemIndex(team);
    if (index >= 0) {
      this.teams.splice(index, 1);
      this.teamsService.SaveTeams(this.teams);
    }
  }

  onNewTeamsSubmit(formData) {
    let eventDays = [];
    debugger

    if (this.teams && this.teams.length > 0) {
      eventDays = [...this.teams];
    }
    if (this.SelectedSpeaker != undefined) {
      formData.value.Speakers = this.SelectedSpeaker;

    }
    if (this.editItemTeams) {
      eventDays[this.findSelectedItemIndex(this.editItemTeams)] = formData.value;
    } else {
      eventDays.push(formData.value);
    }

    if (!this.teams) {
      this.teams = [];
    }
    let valueIndex = eventDays.indexOf(formData.value);
    eventDays.forEach((item, index) => {

      if (index == valueIndex) {
        item.TeamId = valueIndex;
      }
    });

    this.promise = this.teamsService.SaveTeams(eventDays);
    this.promise.then(_ => this.msgs.push({ severity: 'success', summary: 'Success Message', detail: 'Saved' }))
      .catch(err => this.msgs.push({ severity: 'error', summary: 'Error Message', detail: err }));
    this.toggleAddTeamsForm['eventDays'] = eventDays;
    this.editItemTeams = null;
    this.toggleAddTeamsForm();
    this.addTeamsFormStatus = false;
    this.SelectedSpeaker = [];
  }

}
