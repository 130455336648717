import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  baseNewsPath:string = "/News/";
  constructor(private fireBase: AngularFireDatabase) { }

  GetAllNews() {
    return this.fireBase.object(this.baseNewsPath).valueChanges();
  }

  SaveNews(data:any){
    return this.fireBase.object(this.baseNewsPath).set(data);
  }

}
