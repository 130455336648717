import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { DashboardComponent } from './demo/view/dashboard.component';
import { LoginComponent } from './Components/login/login.component';
import { AuthGuard } from './auth-guard.service';
import { NotFoundComponent } from './Components/not-found/not-found.component';
import { RoomsComponent } from './Components/rooms/rooms.component';
import { ProfilesComponent } from './Components/profiles/profiles.component';
import { EventsComponent } from './Components/events/events.component';
import { ProgramsComponent } from './Components/programs/programs.component';
import { SessionsComponent } from './Components/sessions/sessions.component';
import { TeamsComponent } from './Components/teams/teams.component';
import { QuestionsComponent } from './Components/questions/questions.component';
import { VotingComponent } from './Components/voting/voting.component';
import { ViewSessionsComponent } from './Components/view-sessions/view-sessions.component';
import { VoteComponent } from './Components/vote/vote.component';
import { VoteendedComponent } from './Components/voteended/voteended.component';
import { ViewquestionsComponent } from './Components/viewquestions/viewquestions.component';
import { SessionTypeComponent } from './components/session-type/session-type.component';
import { AppUsersComponent } from './components/app-users/app-users.component';
import { AccountActivationComponent } from './components/account-activation/account-activation.component';
import { TicketsComponent } from './components/tickets/tickets.component';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { CategoryPagesComponent } from './components/category-pages/category-pages.component';
import { CategoryComponent } from './components/category/category.component';
import { MenusComponent } from './components/menus/menus.component';
import { PhotoGalleryComponent } from './components/photo-gallery/photo-gallery.component';
import { NewsComponent } from './components/news/news.component';
import { PushNotificationComponent } from './components/push-notification/push-notification.component';
import { EmailUpdateComponent } from './components/email-update/email-update.component';

export const routes: Routes = [

    { path: '', redirectTo: '/login-component', pathMatch: 'full' },
    { path: 'login-component', component: LoginComponent },
    {
        path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard],
        children: [
            { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
            { path: 'app-users', component: AppUsersComponent, canActivate: [AuthGuard] },
            { path: 'rooms', component: RoomsComponent, canActivate: [AuthGuard] },
            { path: 'profiles', component: ProfilesComponent, canActivate: [AuthGuard] },
            { path: 'teams', component: TeamsComponent, canActivate: [AuthGuard] },
            { path: 'events', component: EventsComponent, canActivate: [AuthGuard] },
            { path: 'programs', component: ProgramsComponent, canActivate: [AuthGuard] },
            { path: 'sessions', component: SessionsComponent, canActivate: [AuthGuard] },
            { path: 'sessions-type', component: SessionTypeComponent, canActivate: [AuthGuard] },
            { path: 'votingsetup', component: QuestionsComponent, canActivate: [AuthGuard] },
            { path: 'voting', component: VotingComponent, canActivate: [AuthGuard] },
            { path: 'tickets', component: TicketsComponent, canActivate: [AuthGuard] },
            { path: 'configuration', component: ConfigurationComponent, canActivate: [AuthGuard] },
            { path: 'pages', component: CategoryPagesComponent, canActivate: [AuthGuard] },
            { path: 'category', component: CategoryComponent, canActivate: [AuthGuard] },
            { path: 'menus', component: MenusComponent, canActivate: [AuthGuard] },
            { path: 'photo-gallery', component: PhotoGalleryComponent, canActivate: [AuthGuard] },
            { path: 'news', component: NewsComponent, canActivate: [AuthGuard] },
            { path: 'sendpushnotification', component: PushNotificationComponent, canActivate: [AuthGuard] },
            
        ]
    },
    { path: 'session-detail', component: ViewSessionsComponent, canActivate: [AuthGuard]},
    { path: 'thanks', component: VoteendedComponent, canActivate: [AuthGuard]},
    { path: 'view-user-questions', component: ViewquestionsComponent, canActivate: [AuthGuard]},
    { path: 'vote', component: VoteComponent, canActivate: [AuthGuard] },
    { path: 'account-activation', component: AccountActivationComponent},
    { path: 'email-update', component: EmailUpdateComponent},
    { path: '**', component: NotFoundComponent },

];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes, { useHash: true });
