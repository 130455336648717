import { Component, OnInit } from '@angular/core';
import { AppUsersService } from 'src/app/Services/app-users.service';
import { ActivatedRoute } from '@angular/router';
import { AppUsers } from '../app-users/app-users';

@Component({
  selector: 'app-account-activation',
  templateUrl: './account-activation.component.html',
  styleUrls: ['./account-activation.component.css']
})
export class AccountActivationComponent implements OnInit {
  message: string = "";
  TicketMessage: string = "";
  $key: string = "";
  constructor(private appUsersService: AppUsersService, private route: ActivatedRoute) {
    debugger
    this.route.queryParams.subscribe(params => {
      this.$key = params['Key'];
    });
  }

  ngOnInit() {
    this.message="";
    this.TicketMessage="";
    if (this.$key != null && this.$key != undefined) {
      this.appUsersService.getAppUserByKey(this.$key)
        .subscribe(
          (appUser: AppUsers) => {
            if (appUser != null && appUser != undefined) {
              if (!appUser.VerifiedStatus) {
                appUser.VerifiedStatus = true;
                this.appUsersService.updateAppUser(appUser, this.$key).then(
                  res => {
                    console.log(res);
                    this.message= "Thank you for verifying your email address. You can now access the full range of app features.";
                    this.TicketMessage="If you have purchased any tickets, these will now be available to view from the 'My Tickets' section. Should you wish to update your email address in future you can do so by clicking 'My Profile'.";
                  }
                )
                
               
              }
              else
              {
                this.message = "Email already verified";
              }
            }
            else{
              this.message = "Invalid attempt";
            }

          },
          (error: any) => {
            alert(error);
          }
        );
    }
    else{
      this.message = "Invalid attempt";
    }
  }
}
