import { Injectable } from '@angular/core';
import { IRoomService } from './IRoomService';
import { AngularFireDatabase } from 'angularfire2/database';
import * as _ from 'lodash';
import { AnonymousSubject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class RoomsservicesService{
  
  
      basepathRooms:string = "/Rooms/";
     // basepathQuestions:string = "/Questions/";
     
    constructor(private fireBase: AngularFireDatabase) {
   
    }

    GetAllRooms() {    
        return this.fireBase.object(this.basepathRooms).valueChanges();

    }

    SaveRooms(roomsObject:any) {    
      return this.fireBase.object(this.basepathRooms).set(roomsObject);

  }

    // getAllQuestions() {
    //   return this.fireBase.object(this.basepathQuestions).valueChanges();
    //  }


}
