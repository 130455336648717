import { Component, OnInit } from '@angular/core';
import { Sessions } from '../sessions/sessions';
import { Program } from '../events/events';
import { Rooms } from '../rooms/rooms';
import { EventsService } from 'src/app/Services/events.service';
import { QuestionsService } from 'src/app/Services/questions.service';
import { RoomsservicesService } from 'src/app/Services/roomsservices.service';
import { Router } from '@angular/router';
import { SessionsService } from 'src/app/Services/sessions.service';

@Component({
  selector: 'app-userquestions',
  templateUrl: './userquestions.component.html',
  styleUrls: ['./userquestions.component.css']
})
export class UserquestionsComponent implements OnInit {

  roomsList: Rooms[] = [];  
  programList: Program[] = [];  
  sessions: Sessions[] = [];
 
  constructor(private questionService: QuestionsService, private roomsService: RoomsservicesService,
    private eventService: EventsService, private sessionService: SessionsService,private route:Router) { }

  ngOnInit() {
    this.LoadRooms();
   
  }

  LoadRooms() {  
    this.roomsService.GetAllRooms()
      .subscribe(
        (room: Rooms[]) => {
          if (room != undefined && room.length > 0) {
            this.roomsList = room.sort(x=>x.DisplayOrder);
            room = room.filter(x => x.IsRoomActive === true);           
            this.LoadPrograms();
          }

        },
        (error: any) => {
          alert(error);
        })
  }

  LoadPrograms() {

 
    this.eventService.GetPrograms()
      .subscribe(
        (programs: Program[]) => {
          if (programs != undefined && programs.length > 0) {
            this.programList = programs;
            this.LoadSessions();
          }
        },
        (error: any) => {
          alert(error);
        })
  }

  LoadSessions() {
    this.sessionService.GetSessions()
      .subscribe(
        (session: Sessions[]) => {

          if (session != undefined && session.length > 0) {

            session.forEach((item1, index1) => {
              this.roomsList.forEach((item, index) => {
                this.programList.forEach((itemProgra, indexProgram) => {
                  debugger
                  if (index == item1.RoomId) {
                    item1.RoomName = item.RoomName;
                    item1.RoomId = index;
                  }
                  if (indexProgram == item1.ProgramId) {
                    item1.ProgramName = itemProgra.Title;
                    item1.ProgramId = indexProgram;
                  }
                  let SStartDisplayTime = new Date(item1.SStartTime);
                  let SEndDisplayTime = new Date(item1.SEndTime);
                  item1.SStartDisplayTime = this.timeNow(SStartDisplayTime);
                  item1.SEndDisplayTime= this.timeNow(SEndDisplayTime);
                  
                })
               
                this.sessions = session;
              })

            })
          }

        })

  }

  LoadSessionDetail(roomId:any,programId:any,sessionId:any){
    localStorage.removeItem("RoomId");
    localStorage.removeItem("ProgramId");
    localStorage.removeItem("SessionId");
    localStorage.setItem("RoomId",roomId);
    localStorage.setItem("ProgramId",programId);
    localStorage.setItem("SessionId",sessionId);
  
    window.open('/#/session-detail');

  }

  timeNow(i) {
    var d = new Date(i),
      h = (d.getHours() < 10 ? '0' : '') + d.getHours(),
      m = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
    let time = h + ':' + m;
    return time;
  }

 
}
