import { ISessions, Sessions } from './../sessions/sessions';
import { SessionsService } from 'src/app/Services/sessions.service';
import { Component, OnInit } from '@angular/core';
import { QuestionsService } from 'src/app/Services/questions.service';
import { VotingQUestions, Questions } from '../questions/questions';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Profile } from '../profiles/profiles';
import { ProfilesService } from 'src/app/Services/profiles.service';
import { Teams } from '../teams/teams';
import { TeamsService } from 'src/app/Services/teams.service';
import { Router } from '@angular/router';
import { ConfigurationService } from 'src/app/Services/configuration.service';
import { Configuration } from '../configuration/configuration';


@Component({
  selector: 'app-view-sessions',
  templateUrl: './view-sessions.component.html',
  styleUrls: ['./view-sessions.component.css']
})
export class ViewSessionsComponent implements OnInit {

  constructor(private sessionService: SessionsService, private profileService: ProfilesService,
    private teamService: TeamsService, private router: Router, private questionService: QuestionsService,private configurationService:ConfigurationService) { }
  roomId: any;
  session: Sessions;
  programid: any;
  sessionid: any;
  moderators: string = "";
  speakers: Profile[];
  teams: Teams[];
  showSpinner: boolean = true;
  questionList: Questions[] = [];
  votingQuestion: VotingQUestions;
  question: Questions;
  companyLogo: string;
  ngOnInit() {
    this.roomId = parseInt(localStorage.getItem("RoomId"));
    this.programid = parseInt(localStorage.getItem("ProgramId"));
    this.sessionid = parseInt(localStorage.getItem("SessionId"));
    this.speakers = [];
    this.LoadCompanyDetails();
    this.sessionService.GetSessions()
      .subscribe(
        (session: Sessions[]) => {

          if (session != undefined && session.length > 0) {

            session = session.filter(x => x.RoomId === this.roomId && x.ProgramId == this.programid
              && x.SessionsId == this.sessionid);
            this.session = session[0];



            // this.profileService.getProfilesList()
            //   .subscribe(
            //     (profile: Profile[]) => {
            //       profile.forEach((data, index) => {
            //         if (this.session.Moderators != undefined) {
            //           this.session.Moderators.forEach((dataMod: any, dataModIndex) => {

            //             if (index === dataMod) {
            //               this.moderators = data.Name + "," + this.moderators;
            //             }
            //           });
            //           this.moderators = this.moderators.slice(0, -1)
            //         }



            //         if (this.session.Speakers != undefined) {
            //           this.session.Speakers.forEach((sepakerMod: any, speakerIndex) => {

            //             if (index === sepakerMod) {
            //               this.speakers.push(data);
            //             }
            //           });
            //         }

            //         this.teamService.GetTeams()
            //           .subscribe(
            //             (team: Teams[]) => {
            //               team.forEach((datateam, indexteam) => {
            //                 if (this.session.Teams != undefined) {
            //                   this.session.Teams.forEach((teamMod: any, teamIndex) => {
            //                     if (indexteam === teamMod) {
            //                       if (datateam.Speakers != undefined) {
            //                         datateam.Speakers.forEach((sepakerTeamMod: any, speakerTeamIndex) => {

            //                           if (index === sepakerTeamMod) {
            //                             this.speakers.push(data);
            //                           }
            //                         });
            //                       }
            //                     }
            //                   })

            //                 }
            //               })
            //             })
            //       });

            //       console.log(this.speakers);

            //     })


            this.showSpinner = false;
          }


        })
    this.GetQuestions()
  }

  LoadCompanyDetails() {
    //  this.configurationService.getConfiguration().subscribe(element => {
    //    let data = element
    //   console.log(data.CompanyLogo)
    // });
    this.configurationService.getConfiguration()
    .subscribe(
      (config: Configuration) => {
        this.companyLogo = config.CompanyLogo
      })
  }

  StartVoting() {
    debugger

    if (this.questionList != undefined && this.votingQuestion) {

      let count = this.questionList.length;
      let completed = this.questionList.filter(x => x.IsCompleted === true && x.IsActive === false);

      let completedObj = completed[count - 1];
      if (completedObj != undefined) {
        if (completedObj.IsActive == false && completedObj.IsCompleted == true) {
          this.router.navigate(['/thanks'])
        }
      }
      else {

        this.question = this.questionList[0];
        this.question.IsActive = true;
        this.question.IsCompleted = false;
        this.questionService.SaveQuestionByVotingandQuestionId(this.votingQuestion.VotingId, this.question.QuestionId, this.question);

        this.router.navigate(['/vote'])
      }
    }

    // this.router.navigate(['/vote'])

  }


  GetQuestions() {
    this.questionService.GetQuestions()
      .subscribe(
        (question: VotingQUestions[]) => {
          if (question != undefined && question.length > 0) {

            question = question.filter(x => x.RoomId === this.roomId && x.SessionId === this.sessionid &&
              x.ProgramId === this.programid);
            if (question != undefined) {
              this.votingQuestion = question[0];
              this.questionList = question[0].Questions;
              if (this.questionList != undefined) {

              }
            }
          }
        })
  }

}