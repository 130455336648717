import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
  providedIn: 'root'
})
export class AppUsersService {

  
  baseAppUsersPath:string = "/AppUsers/";
  constructor(private fireBase: AngularFireDatabase) { }



  getAppUsersList() {
    return this.fireBase.object(this.baseAppUsersPath).valueChanges();
  }

  saveAppUser(data:any){
    return this.fireBase.object(this.baseAppUsersPath).set(data);
  }
    
  getAppUserDataByKey($key:string){
    return this.fireBase.object(this.baseAppUsersPath + $key).valueChanges();
  }
  removeUser($key:string){
    return this.fireBase.object(this.baseAppUsersPath + $key).remove();
  }

  updateAppUser(data:any,$key:any){
    return this.fireBase.object(this.baseAppUsersPath + $key).update(data);
  }

  getAppUserByKey($key:any){
    debugger
    return this.fireBase.object(this.baseAppUsersPath + $key).valueChanges();
  }
}
