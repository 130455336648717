import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
//import { User } from '@firebase/auth-types';
import { AngularFireAuth } from 'angularfire2/auth';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs';
@Injectable()
export class AuthService {
  user:Observable<firebase.User>;
  constructor(public  afAuth:  AngularFireAuth, public  router:  Router) {
    this.user = afAuth.authState;
    this.afAuth.authState.subscribe(user => {
      if (user) {
        
        localStorage.setItem('user', JSON.stringify(user));
      } else {
        this.user==null;
        localStorage.setItem('user', null);
      }
    })
  }
  result :string="";
  
  logout(){
   this.afAuth.auth.signOut();
   this.user=null;
    localStorage.removeItem('user');
    this.router.navigate(['/login-component']);
}

  // signUp(email:string,password:string){
  //   this.firebaseAuth.auth.createUserWithEmailAndPassword(email,password).then(value=>{
  //     console.log('Success !!',value);
      
  //   }).catch(err=>{
  //     console.log('Error !!',err.message);
  //   })
  // }

  // login(email:string,password:string) : string {  
  //   debugger
  //   this.firebaseAuth.auth.signInWithEmailAndPassword(email,password).then((value)=>{     
  //     console.log(value);
  //     this.result = value;     
     
  //   }).catch((err)=>{
  //    return this.result=this.test(err.message);     
  //   })
    
  //    return this.result;
 
  // } 

  // logout() {
  //   this.firebaseAuth
  //     .auth
  //     .signOut();
  // }
  // // .catch(
  // //   (err) => {
  // //   console.log(err);
  // //   this.error = err;
  // // })
  // test(value){
  //   debugger
  //   return value;
  // }

  

}
