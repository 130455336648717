import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from 'src/app/Services/configuration.service';
import { Configuration } from '../configuration/configuration';

@Component({
  selector: 'app-voteended',
  templateUrl: './voteended.component.html',
  styleUrls: ['./voteended.component.css']
})
export class VoteendedComponent implements OnInit {
  companyLogo: string;
  constructor(private configurationService: ConfigurationService) { }

  ngOnInit() {
    this.LoadCompanyDetails();
  }

  LoadCompanyDetails() {
   
    this.configurationService.getConfiguration()
    .subscribe(
      (config: Configuration) => {
        this.companyLogo = config.CompanyLogo
      })
  }
}
