export interface IFcmToken {
    deviceid: string;
    userid:string;
    notificationDate:string;
    $key:string;
}

export class FcmToken implements IFcmToken {
    deviceid: string;
    userid:string;
    notificationDate:string;
    $key:string;
}