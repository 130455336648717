import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { AppRoutes } from './app.routes';

import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DataListModule, ConfirmationService } from 'primeng/primeng';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { GalleriaModule } from 'primeng/galleria';
import { GrowlModule } from 'primeng/growl';
import { InplaceModule } from 'primeng/inplace';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { LightboxModule } from 'primeng/lightbox';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SpinnerModule } from 'primeng/spinner';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { VirtualScrollerModule } from 'primeng/virtualscroller';

import { AppComponent } from './app.component';
import { AppMenuComponent, AppSubMenuComponent } from './app.menu.component';
import { AppBreadcrumbComponent } from './app.breadcrumb.component';
import { AppTopBarComponent } from './app.topbar.component';
import { AppFooterComponent } from './app.footer.component';
import { DashboardComponent } from './demo/view/dashboard.component';
import { SampleDemoComponent } from './demo/view/sampledemo.component';
import { FormsDemoComponent } from './demo/view/formsdemo.component';
import { DataDemoComponent } from './demo/view/datademo.component';
import { PanelsDemoComponent } from './demo/view/panelsdemo.component';
import { OverlaysDemoComponent } from './demo/view/overlaysdemo.component';
import { MenusDemoComponent } from './demo/view/menusdemo.component';
import { MessagesDemoComponent } from './demo/view/messagesdemo.component';
import { MiscDemoComponent } from './demo/view/miscdemo.component';
import { EmptyDemoComponent } from './demo/view/emptydemo.component';
import { ChartsDemoComponent } from './demo/view/chartsdemo.component';
import { FileDemoComponent } from './demo/view/filedemo.component';
import { UtilsDemoComponent } from './demo/view/utilsdemo.component';
import { DocumentationComponent } from './demo/view/documentation.component';

import { CarService } from './demo/service/carservice';
import { CountryService } from './demo/service/countryservice';
import { EventService } from './demo/service/eventservice';
import { NodeService } from './demo/service/nodeservice';

import { BreadcrumbService } from './breadcrumb.service';
import { LoginComponent } from './Components/login/login.component';
import { NotFoundComponent } from './Components/not-found/not-found.component';

//firebase imports
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule, AngularFireDatabase } from 'angularfire2/database';
import { AngularFireAuthModule, AngularFireAuth } from 'angularfire2/auth';
import { AuthService } from './Services/auth-service.service';
import { environment } from './../environments/environment';
import { AuthGuard } from './auth-guard.service';
import { RoomsComponent } from './Components/rooms/rooms.component';
import { RoomsservicesService } from './Services/roomsservices.service';

import { ProfilesComponent } from './Components/profiles/profiles.component';
import { ProfilesService } from './Services/profiles.service';
import { UploadFileServiceService } from './Services/upload-file-service.service';
import { EventsComponent } from './Components/events/events.component';
import { EventsService } from './Services/events.service';
import { ProgramsComponent } from './Components/programs/programs.component';
import { SessionsComponent } from './Components/sessions/sessions.component';
import { SessionsService } from './Services/sessions.service';
import { TeamsComponent } from './Components/teams/teams.component';
import { TeamsService } from './Services/teams.service';
import { QuestionsComponent } from './Components/questions/questions.component';
import { QuestionsService } from './Services/questions.service';

//chart
import { jqxChartComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxchart';
import { jqxBarGaugeComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxbargauge';

import { VotingComponent } from './Components/voting/voting.component'
import { ViewSessionsComponent } from './Components/view-sessions/view-sessions.component';
import { VoteComponent } from './Components/vote/vote.component';
import { RouterModule } from '@angular/router';
import { SpinnerComponent } from './Components/spinner/spinner.component';
import { VoteendedComponent } from './Components/voteended/voteended.component';
import { UserquestionsComponent } from './Components/userquestions/userquestions.component';
import { ViewquestionsComponent } from './Components/viewquestions/viewquestions.component';
import { SessionTypeComponent } from './components/session-type/session-type.component';
import { AppUsersComponent } from './components/app-users/app-users.component';
import { SessionTypeService } from './Services/session-type.service';
import { AppUsersService } from './Services/app-users.service';
import { AccountActivationComponent } from './components/account-activation/account-activation.component';
import { TicketsComponent } from './components/tickets/tickets.component';
import { TicketsService } from './Services/ticketsservice.service';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { ConfigurationService } from './Services/configuration.service';
import { CategoryPagesComponent } from './components/category-pages/category-pages.component';
import { CategoryPageService } from './Services/categorypage.service';
import { CategoryComponent } from './components/category/category.component';
import { CategoryService } from './Services/category.service';
import { MenusComponent } from './components/menus/menus.component';
import { MenuService } from './Services/menu.service';
import { PhotoGalleryComponent } from './components/photo-gallery/photo-gallery.component';
import { PhotoGalleryService } from './Services/photo-gallery.service';
import { GroupByPipe } from './groupbypipe';
import { NewsComponent } from './components/news/news.component';
import { NewsService } from './Services/news.service';
import { FcmtokensService } from './Services/fcmtokens.service';
import { UsernotificationService } from './Services/usernotification.service';
import { PushNotificationComponent } from './components/push-notification/push-notification.component';
import { EmailUpdateComponent } from './components/email-update/email-update.component';



@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutes,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        ChartModule,
        CheckboxModule,
        ChipsModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DropdownModule,
        DataListModule,
        EditorModule,
        FieldsetModule,
        FileUploadModule,
        FullCalendarModule,
        GalleriaModule,
        GrowlModule,
        InplaceModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        ScrollPanelModule,
        SelectButtonModule,
        SlideMenuModule,
        SliderModule,
        SpinnerModule,
        SplitButtonModule,
        StepsModule,
        ReactiveFormsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TerminalModule,
        TieredMenuModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        //firebase initialization
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        RouterModule 
    ],
    declarations: [
        AppComponent,
        AppMenuComponent,
        AppSubMenuComponent,
        AppBreadcrumbComponent,
        AppTopBarComponent,
        AppFooterComponent,
        DashboardComponent,
        SampleDemoComponent,
        FormsDemoComponent,
        DataDemoComponent,
        PanelsDemoComponent,
        OverlaysDemoComponent,
        MenusDemoComponent,
        MessagesDemoComponent,
        MessagesDemoComponent,
        MiscDemoComponent,
        ChartsDemoComponent,
        EmptyDemoComponent,
        FileDemoComponent,
        UtilsDemoComponent,
        DocumentationComponent,
        LoginComponent,
        NotFoundComponent,
        RoomsComponent,

        ProfilesComponent,
        EventsComponent,
        ProgramsComponent,
        SessionsComponent,
        TeamsComponent,
        QuestionsComponent,
        VotingComponent,
        ViewSessionsComponent,
        jqxChartComponent,
        jqxBarGaugeComponent,
        VoteComponent,
        SpinnerComponent,
        VoteendedComponent,
        UserquestionsComponent,
        ViewquestionsComponent,
        SessionTypeComponent,
        AppUsersComponent,
        AccountActivationComponent,
        TicketsComponent,
        ConfigurationComponent,
        CategoryPagesComponent,
        CategoryComponent,
        MenusComponent,
        PhotoGalleryComponent,
        NewsComponent,
        PushNotificationComponent,
        EmailUpdateComponent,

    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy, },
        CarService, CountryService, EventService, NodeService, BreadcrumbService, AuthService, AuthGuard, RoomsservicesService,
        ProfilesService, UploadFileServiceService, EventsService, SessionsService, ConfirmationService, TeamsService,SessionTypeService,
         QuestionsService,AppUsersService,TicketsService,ConfigurationService,
         CategoryPageService,CategoryService,MenuService,PhotoGalleryService,GroupByPipe,NewsService,FcmtokensService,UsernotificationService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
