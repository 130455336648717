import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Message } from 'primeng/api';
import { PhotoGallery } from './photogallery';
import { FormBuilder, Validators } from '@angular/forms';
import { PhotoGalleryService } from 'src/app/Services/photo-gallery.service';

@Component({
  selector: 'app-photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styleUrls: ['./photo-gallery.component.css']
})
export class PhotoGalleryComponent implements OnInit {

  promise: Promise<void>

  msgs: Message[] = [];
  selectedEmail: "";
  PhotoGalleryList: PhotoGallery[] = [];
  pendingGalleryList: PhotoGallery[] = [];
  invalidGalleryList: PhotoGallery[] = [];
  validGalleryList: PhotoGallery[] = [];
  showImageDialogue: boolean = false;
  isNewPhotoGallery: boolean;
  editItemPhotoGallery: any;
  PhotoGalleryForm: any;
  addPhotoGalleryFormStatus: boolean;
  objectKeys = Object.keys;
  dataExists: boolean = false;
  PhotoGallery: PhotoGallery = new PhotoGallery();
  $key: string = "";
  showSpinner: boolean = false;
  GroupedPhotoGalleryList: {} = {};
  PhotoGalleryListData: any[] = [];
  displayDialog: boolean;
  selectePhotoGallery: PhotoGallery;
  responsiveOptions: { breakpoint: string; numVisible: number; numScroll: number; }[];
  numScroll: number;
  constructor(private photoGalleryService: PhotoGalleryService, private formBuilder: FormBuilder) {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
    encapsulation: ViewEncapsulation.None
  }

  ngOnInit() {

    this.LoadPhotoGalleryList();

  }



  LoadPhotoGalleryList() {
    this.photoGalleryService.getPhotoGalleryList()
      .subscribe(
        (PhotoGalleryList: PhotoGallery[]) => {
          this.PhotoGalleryList = [];
          for (let key in PhotoGalleryList) {
            this.PhotoGallery = new PhotoGallery();
            this.PhotoGallery = PhotoGalleryList[key];
            this.PhotoGallery.key = key;
            this.PhotoGalleryList.push(this.PhotoGallery);
            this.dataExists = true;
          }


          this.validGalleryList = this.PhotoGalleryList.filter(x => x.IsValid === true && x.IsPending === false);
          this.invalidGalleryList = this.PhotoGalleryList.filter(x => x.IsValid === false && x.IsPending === false);
          this.pendingGalleryList = this.PhotoGalleryList.filter(x => x.IsPending === true && x.IsValid === false);
          for (let data of this.validGalleryList) {
            data.IsValid = false;
          }
          for (let data of this.pendingGalleryList) {
            data.IsPending = false;
          }
          //group by user email
          // var GroupedPhotoGalleryList = {};
          // this.PhotoGalleryList.forEach(function (a) {
          //   GroupedPhotoGalleryList[a.UploadedBy] = GroupedPhotoGalleryList[a.UploadedBy] || [];
          //   GroupedPhotoGalleryList[a.UploadedBy].push({
          //     ImageUrl: a.ImageUrl, ImageName: a.ImageName,
          //     ImageExtension: a.ImageExtension, UploadedBy: a.UploadedBy,
          //     IsValid: a.IsValid, UploadedDate: a.UploadedDate
          //   });
          // });
          //iterate over object to get user email
          // Object.keys(GroupedPhotoGalleryList).forEach((key) => {
          //   this.PhotoGalleryListData.push({ Email: key });
          // })



        },
        (error: any) => {
          alert(error);
        }
      );

  }



  buildPageForm(photoGalleryData) {
    const page = photoGalleryData;
    this.PhotoGalleryForm = this.formBuilder.group({
      ImageUrl: [page ? page.ImageUrl : ''],
      ImageName: [page ? page.ImageName : ''],
      ImageExtension: [page ? page.ImageExtension : ''],
      UploadedBy: [page ? page.UploadedBy : ''],
      IsValid: [page ? page.IsValid : ''],
      UploadedDate: [page ? page.UploadedDate : ''],
    });
  }


  editPhotoGalleryForm(photoGalleryData: any) {
    this.isNewPhotoGallery = false;
    this.editItemPhotoGallery = photoGalleryData;
    this.$key = this.editItemPhotoGallery.$key;
    this.addPhotoGalleryFormStatus = true;
    if (this.addPhotoGalleryFormStatus) {
      this.buildPageForm(this.editItemPhotoGallery)

    }
  }


  onSubmit(formData) {
    // this.PhotoGalleryService.updatePhotoGallery(formData.value, this.$key).then(
    //   res => {
    //     this.msgs.push({ severity: 'success', summary: 'Success Message', detail: 'Saved' })
    //   })
    // this.editItemPhotoGallery = null;
    this.selectedEmail = null;
    this.addPhotoGalleryFormStatus = false;
    this.LoadPhotoGalleryList();

  }

  ShowImagePopUp(data) {
    this.editItemPhotoGallery = data;
    this.showImageDialogue = true;

  }

  editPhotoGallery(userEmail) {
    // this.dataExists=false;
    // this.selectedEmail = userEmail.Email;

    // this.validEmailList = this.PhotoGalleryList.filter(x=>x.UploadedBy===this.selectedEmail && x.IsValid===true);
    // this.invalidEmailList = this.PhotoGalleryList.filter(x=>x.UploadedBy===this.selectedEmail && x.IsValid===false);
    // if(this.validEmailList!=undefined && this.invalidEmailList!=undefined){
    //   this.dataExists=true;
    // }
  }

  SaveInvalidImage() {
    let allSaved = true;
    console.log("invalidGalleryList", this.invalidGalleryList)
    for (let data of this.invalidGalleryList) {
      this.photoGalleryService.updatePhotoGallery(data, data.key).then(
        res => {
        }).catch(() => allSaved = false)
    }
    if (allSaved === true) {
      this.msgs.push({ severity: 'success', summary: 'Success Message', detail: 'Saved' })
    }
    else {
      this.msgs.push({ severity: 'error', summary: 'Error While Saving', detail: 'Error While Saving' })
    }
    this.LoadPhotoGalleryList();
  }

  SaveValidImage() {
    let allSaved = true;
    console.log("validGalleryList", this.validGalleryList)
    for (let data of this.validGalleryList) {
      if (data.IsValid === true) {
        data.IsValid = false;
      }
      else {
        data.IsValid = true;
      }
      this.photoGalleryService.updatePhotoGallery(data, data.key).then(
        res => {
        }).catch(() => allSaved = false)
    }
    if (allSaved === true) {
      this.msgs.push({ severity: 'success', summary: 'Success Message', detail: 'Saved' })
    }
    else {
      this.msgs.push({ severity: 'error', summary: 'Error While Saving', detail: 'Error While Saving' })
    }
    this.LoadPhotoGalleryList();
  }


  SavePendingValidImage() {
    let allSaved = true;
    console.log(this.pendingGalleryList);
    for (let data of this.pendingGalleryList) {
      if (data.IsPending === true) {
        debugger
        data.IsPending = false;
        data.IsValid = true;
        this.photoGalleryService.updatePhotoGallery(data, data.key).then(
          res => {
          }).catch(() => allSaved = false)
      }
    }
    if (allSaved === true) {
      this.msgs.push({ severity: 'success', summary: 'Success Message', detail: 'Saved' })
    }
    else {
      this.msgs.push({ severity: 'error', summary: 'Error While Saving', detail: 'Error While Saving' })
    }
    this.LoadPhotoGalleryList();
  }

  SavePendingInvalidImage() {
    let allSaved = true;
    console.log(this.pendingGalleryList);
    for (let data of this.pendingGalleryList) {
      // data.IsValid = data.IsValid[0];
      if (data.IsPending === true) {
        data.IsPending = false;
        data.IsValid = false;
        debugger
        this.photoGalleryService.updatePhotoGallery(data, data.key).then(
          res => {
          }).catch(() => allSaved = false)
      }
    }
    if (allSaved === true) {
      this.msgs.push({ severity: 'success', summary: 'Success Message', detail: 'Saved' })
    }
    else {
      this.msgs.push({ severity: 'error', summary: 'Error While Saving', detail: 'Error While Saving' })
    }
    this.LoadPhotoGalleryList();
  }

  selectGallery(event: Event, photoGallery: PhotoGallery) {
    this.selectePhotoGallery = photoGallery;
    this.displayDialog = true;
    event.preventDefault();
  }

  deleteGallery(event: Event, photoGallery: PhotoGallery) {
    // this.selectePhotoGallery = photoGallery;
    // this.displayDialog = true;
    // event.preventDefault();
    let gallery = photoGallery;
    this.photoGalleryService.deletePhotoGallery(gallery.key).then(x => {
      this.msgs.push({
        severity: 'success', summary: 'Success Message', detail: 'Delete Succesfully'
      })
      this.LoadPhotoGalleryList();
    })
  }

  onDialogHide() {
    this.selectePhotoGallery = null;
  }
}
