import { Component, OnInit, ViewChild } from '@angular/core';
import { Rooms } from '../rooms/rooms';
import { Program } from '../events/events';
import { QuestionsService } from 'src/app/Services/questions.service';
import { RoomsservicesService } from 'src/app/Services/roomsservices.service';
import { EventsService } from 'src/app/Services/events.service';
import { VotingQUestions, Questions } from '../questions/questions';
import { SessionsService } from 'src/app/Services/sessions.service';
import { Sessions } from '../sessions/sessions';
import { Message } from 'primeng/api';
import { jqxChartComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxchart';
import { Router } from '@angular/router';
import { SessionQuestions } from '../sessions/UserQuestions';

@Component({
  selector: 'app-voting',
  templateUrl: './voting.component.html',
  styleUrls: ['./voting.component.css']
})
export class VotingComponent implements OnInit {
  @ViewChild('myChart') myChart: jqxChartComponent;
  msgs: Message[] = [];
  roomsList: Rooms[] = [];
  roomsListDropDown: any[] = [];
  programList: Program[] = [];
  programListDropDown: any[] = [];
  dayListDropDown: any[] = [];
  sessionListDropDown: any[] = [];
  sessions: Sessions[] = [];
  questions: VotingQUestions[] = [];
  sessionQuestions: SessionQuestions[] = [];

  constructor(private questionService: QuestionsService, private roomsService: RoomsservicesService,
    private eventService: EventsService, private sessionService: SessionsService, private route: Router) { }

  ngOnInit() {
    this.LoadRooms();
    this.GetUserQuestion();
  }

  LoadRooms() {


    this.roomsService.GetAllRooms()
      .subscribe(
        (room: Rooms[]) => {

          if (room != undefined && room.length > 0) {
            this.roomsList = room;

            this.LoadPrograms();
          }

        },
        (error: any) => {
          alert(error);
        })
  }

  LoadPrograms() {


    this.eventService.GetPrograms()
      .subscribe(
        (programs: Program[]) => {

          if (programs != undefined && programs.length > 0) {
            this.programList = programs;

            this.LoadSessions();
          }
        },
        (error: any) => {
          alert(error);
        })
  }

  LoadSessions() {
    this.sessionService.GetSessions()
      .subscribe(
        (session: Sessions[]) => {

          if (session != undefined && session.length > 0) {

            session.forEach((item1, index1) => {
              this.roomsList.forEach((item, index) => {
                this.programList.forEach((itemProgra, indexProgram) => {
                  debugger
                  if (index == item1.RoomId) {
                    item1.RoomName = item.RoomName;
                    item1.RoomId = index;
                  }
                  if (indexProgram == item1.ProgramId) {
                    item1.ProgramName = itemProgra.Title;
                    item1.ProgramId = indexProgram;
                  }
                  let SStartDisplayTime = new Date(item1.SStartTime);
                  let SEndDisplayTime = new Date(item1.SEndTime);
                  item1.SStartDisplayTime = this.timeNow(SStartDisplayTime);
                  item1.SEndDisplayTime = this.timeNow(SEndDisplayTime);
                  // if(item1.Day==1){
                  //   item1.DayName="Day 1";
                  // }
                  // if(item1.Day==2){
                  //   item1.DayName="Day 2";
                  // }
                })
                item1.VotingQuestions = false;
                this.questionService.GetQuestions()
                  .subscribe(
                    (question: VotingQUestions[]) => {
                      question = question.filter(x => x.RoomId === item1.RoomId && x.ProgramId === item1.ProgramId && x.SessionId === item1.SessionsId)
                      question.forEach((itequestion) => {
                        if (itequestion.Questions != undefined) {
                          item1.VotingQuestions = true;
                        }
                      })
                    })

                this.sessions = session;
              })

            })
          }

        })

  }

  LoadSessionDetail(roomId: any, programId: any, sessionId: any) {
    localStorage.removeItem("RoomId");
    localStorage.removeItem("ProgramId");
    localStorage.removeItem("SessionId");
    localStorage.setItem("RoomId", roomId);
    localStorage.setItem("ProgramId", programId);
    localStorage.setItem("SessionId", sessionId);

    window.open('/#/session-detail');

  }

  LoadUserQuestionDetail(roomId: any, programId: any, sessionId: any) {
    localStorage.removeItem("RoomId");
    localStorage.removeItem("ProgramId");
    localStorage.removeItem("SessionId");
    localStorage.setItem("RoomId", roomId);
    localStorage.setItem("ProgramId", programId);
    localStorage.setItem("SessionId", sessionId);

    window.open('/#/view-user-questions');

  }

  timeNow(i) {
    var d = new Date(i),
      h = (d.getHours() < 10 ? '0' : '') + d.getHours(),
      m = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
    let time = h + ':' + m;
    return time;
  }

  GetUserQuestion() {
    this.sessionService.GeSessionsQuestionsSessions().subscribe((sessionQuestions: SessionQuestions[]) => {
      this.sessionQuestions = sessionQuestions;
      if (sessionQuestions == null) {
        this.sessionQuestions = [];
      }
    })

  }

}
