import {Component} from '@angular/core';
import {AppComponent} from './app.component';
import { AngularFireAuth } from 'angularfire2/auth';
import { Router } from '@angular/router';
import { DashboardComponent } from './demo/view/dashboard.component';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {

    constructor(public app: DashboardComponent,private firebaseAuth:AngularFireAuth,private router:Router) {}

    Logout() {
        this.firebaseAuth
        .auth
        .signOut();
        this.router.navigate(['/login-component']);

     
      
      }


}
