export interface IAppUsers {
    FirstName: string;
    LastName: string;
    Email: string;
    Password: string;
    Telephone: string;
    UserType: string;
    VerifiedStatus: boolean;
    IsSuspended: boolean;
    $key:string;
}

export class AppUsers implements IAppUsers {
    FirstName: string;
    LastName: string;
    Email: string;
    Password: string;
    Telephone: string;
    UserType: string;
    VerifiedStatus: boolean;
    IsSuspended: boolean;
    $key:string;
}