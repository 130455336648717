import { Component, OnInit } from '@angular/core';
import { EventsService } from 'src/app/Services/events.service';
import { UploadFileServiceService } from 'src/app/Services/upload-file-service.service';
import { FormBuilder, Validators, FormArray } from '@angular/forms';
import { Program, Events, Days } from './events';
import { FileUpload } from './../../Models/FileUpload';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {

  promise: Promise<void>
  currentFileUpload: FileUpload;
  progress: { percentage: number } = { percentage: 0 }
  msgs: Message[] = [];


  events: Events[] = [];
  selectedFiles: any;
  eventData;
  isNewEdit: boolean;
  editItemEvent: any;
  EventForm: any;
  addEventFormStatus: boolean;
  eventId: any;
  newDay: boolean;
  day: any;
  displayDialog: boolean;

  daysList: Days[] = [];
  selectedDay: any;
  showCustomEventList: boolean;
  customEventList: any[] = [];
  constructor(private eventsService: EventsService, private fileUploadService: UploadFileServiceService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.LoadEvents();
    this.LoadCustomEventsList();
  }


  LoadEvents() {
    this.eventsService.GetEvents()
      .subscribe(
        (profile: Events[]) => {
          this.events = profile;
          this.buildPageForm(this.events);
        },
        (error: any) => {
          alert(error);
        }
      );

  }

  LoadCustomEventsList() {
    this.eventsService.getCustEventList()
      .subscribe(
        (customEventsList: any[]) => {
          debugger
          if (customEventsList != null && customEventsList != undefined) {
            // customEventsList.forEach((item1, index) => {
            //   this.customEventList.push({ label: item1.ProductTitle, value: item1.ProductID });
            // });
            for (let key in customEventsList) {
              customEventsList[key].forEach((item1, index) => {
                this.customEventList.push({ label: item1.ProductTitle, value: item1.ProductID });
              });

            }
          }
        },
        (error: any) => {
          alert(error);
        }
      );

  }



  buildPageForm(eventData) {
    const page = eventData;
    this.EventForm = this.formBuilder.group({
      EventTitle: [page ? page.EventTitle : '', Validators.required],
      ShortDescription: [page ? page.ShortDescription : ''],
      Description: [page ? page.Description : ''],
      StartDate: [page ? page.StartDate : ''],
      Image: [page ? page.Image : ''],
      EndDate: [page ? page.EndDate : ''],
      Active: [page ? page.Active : false],
      EventId: [page ? page.EventId : ''],
      IsCustomEventSelected: [page ? page.IsCustomEventSelected : false],
      CustomEventId: [page ? page.CustomEventId : []]
    });
  }



  findSelectedItemIndex(item): number {
    return this.events.indexOf(item);
  }


  onNewEventSubmit(formData) {
    if(formData.value.IsCustomEventSelected==false){
      formData.value.CustomEventId="";
    }
    let eventDays = [];
    if (this.daysList != null && this.daysList != undefined) {
      this.daysList.forEach((item, index) => {
        if (item.Date != null) {
          item.ConvertedDate = this.convertDate(item.Date);
        }
      })
    }
    if (this.currentFileUpload != null && this.currentFileUpload.url != null) {
      if (formData.value.Image != null && formData.value.Image != "") {
        this.fileUploadService.removeFireBaseImage(formData.value.Image);
        formData.value.Image = '';
      }
      formData.value.Image = this.currentFileUpload.url;

    }
    if (this.events && this.events.length > 0) {
      eventDays = [...this.events];
    }
    if (this.editItemEvent) {
      formData.value.Days = this.daysList;
      eventDays[this.findSelectedItemIndex(this.editItemEvent)] = formData.value;
    } else {
      formData.value.Days = this.daysList;
      eventDays.push(formData.value);
    }

    if (!this.events) {
      this.events = [];
    }
    let valueIndex = eventDays.indexOf(formData.value);
    eventDays.forEach((item, index) => {

      if (index == valueIndex) {
        item.EventId = valueIndex;
      }
    });



    this.promise = this.eventsService.SaveEvents(eventDays);
    this.promise.then(_ => this.msgs.push({ severity: 'success', summary: 'Success Message', detail: 'Saved' }))
      .catch(err => this.msgs.push({ severity: 'error', summary: 'Error Message', detail: err }));
    this.toggleAddEventForm['eventDays'] = eventDays;
    this.editItemEvent = null;
    this.currentFileUpload = new FileUpload(null);
    this.toggleAddEventForm();
    this.progress.percentage = 0;
    this.addEventFormStatus = false;
    this.showCustomEventList=false;
    this.currentFileUpload=null;
  }

  toggleAddEventForm() {
    this.isNewEdit = true;
    this.editItemEvent = null;
    this.EventForm = this.formBuilder.group({
      EventTitle: ['', Validators.required],
      ShortDescription: '',
      Description: '',
      StartDate: '',
      Image: '',
      EndDate: [''],
      Active: false,
      EventId: '',
      IsCustomEventSelected: false,
      CustomEventId: []
    });
    this.addEventFormStatus = true;
    this.daysList = [];
  }



  editEventForm(eventData: any, eventId: any) {
    this.isNewEdit = false;
    this.eventId = eventId;
    this.editItemEvent = eventData;
    this.addEventFormStatus = true;
    this.showCustomEventList = eventData.IsCustomEventSelected;
    if (this.addEventFormStatus) {
      this.buildPageForm(this.editItemEvent)
    }
    if (eventData.Days != undefined) {
      this.daysList = eventData.Days;
      if (this.daysList != null && this.daysList != undefined) {
        this.daysList.forEach((item, index) => {
          if (item.ConvertedDate != null) {
            item.Date = new Date(item.ConvertedDate);
          }
        })
      }
    }
      
  }


  showDialogToAdd() {
    this.newDay = true;
    this.day = new Days();

    this.displayDialog = true;
  }

  deleteEventForm(index) {
    debugger
    this.events.splice(index, 1);
    this.eventsService.SaveEvents(this.events);
  }


  //#endregion


  //#region  Helpers
  //Upload Image
  ////Image Upload for events
  selectFile(event) {
    const file = event.target.files.item(0);
    if (file.type.match('image.*')) {
      this.selectedFiles = event.target.files;
      this.upload();
    } else {
      alert('invalid format!');
    }

  }

  upload() {

    const file = this.selectedFiles.item(0)
    this.selectedFiles = undefined
    this.currentFileUpload = new FileUpload(file);
    this.fileUploadService.pushFileToStorage(this.currentFileUpload, this.progress)

  }


  save() {
    debugger
    let daysL = [...this.daysList];
    if (this.newDay) {
      daysL.push(this.day);
    }
    else {
      daysL[this.daysList.indexOf(this.selectedDay)] = this.day;
    }
    let valueIndex = daysL.indexOf(this.day);
    daysL.forEach((item, index) => {
      if (index == valueIndex) {

        item.DayId = valueIndex;
      }

    })
    this.daysList = daysL;
    this.day = null;
    this.displayDialog = false;
  }

  delete() {
    let index = this.daysList.indexOf(this.selectedDay);
    this.daysList = this.daysList.filter((val, i) => i != index);
    this.day = null;
    this.displayDialog = false;
  }

  onRowSelect(event) {
    debugger
    this.newDay = false;
    this.day = new Days();
    this.day = event.data;
    this.day = this.cloneDay(this.day);
    this.displayDialog = true;
  }

  cloneDay(c: any): any {
    let day = {};
    for (let prop in c) {
      day[prop] = c[prop];
    }
    return day;
  }

  convertDate(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  IsCustomEventChanged(e) {
    this.showCustomEventList = e;
  }
}
