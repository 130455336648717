import { Component, OnInit } from '@angular/core';
import { AppUsersService } from 'src/app/Services/app-users.service';
import { ActivatedRoute } from '@angular/router';
import { AppUsers } from '../app-users/app-users';

@Component({
  selector: 'app-email-update',
  templateUrl: './email-update.component.html',
  styleUrls: ['./email-update.component.css']
})
export class EmailUpdateComponent implements OnInit {
  message: string = "";
  $key: string = "";
  constructor(private appUsersService: AppUsersService, private route: ActivatedRoute) {
    debugger
    this.route.queryParams.subscribe(params => {
      this.$key = params['Key'];
    });
  }

  ngOnInit() {
    this.message="";
    if (this.$key != null && this.$key != undefined) {
      this.appUsersService.getAppUserByKey(this.$key)
        .subscribe(
          (appUser: AppUsers) => {
            if (appUser != null && appUser != undefined) {
              if (!appUser.VerifiedStatus) {
                appUser.VerifiedStatus = true;
                this.appUsersService.updateAppUser(appUser, this.$key).then(
                  res => {
                    debugger
                    this.message= "Your Email has been updated successfully!!!.";                   
                  }
                )             
               
              }
              else
              {
                this.message = "Email already updated";
              }
            }
            else{
              this.message = "Invalid attempt";
            }

          },
          (error: any) => {
            alert(error);
          }
        );
    }
    else{
      this.message = "Invalid attempt";
    }
  }

}
