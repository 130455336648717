import { Component, OnInit } from '@angular/core';

import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/Services/auth-service.service';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-login-component',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  user: Observable<firebase.User>;
  error: string = "";
  constructor(private authService: AuthService, private firebaseAuth: AngularFireAuth, private router: Router) {
    this.user = firebaseAuth.authState;
  }


  ngOnInit() {
  }

  onSubmit(formData) {
    this.firebaseAuth.auth.signInWithEmailAndPassword(formData.value.email, formData.value.password).then((value) => {
      localStorage.setItem("loggedInUser", formData.value.email);
      if (formData.value.email === "moderators@akikodesign.com") {
        this.router.navigate(['/dashboard/voting']);
      }
      else {
        this.router.navigate(['/dashboard/rooms']);
      }

    }).catch((err) => {
      this.error = err.message;
    })

  }
}
