import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
  providedIn: 'root'
})
export class QuestionsService {

  basepathQuestions: string = "/QuestionsNew/";
  basepathTypes: string = "/Type/";
  constructor(private fireBase: AngularFireDatabase) {

  }

  GetQuestions() {
    return this.fireBase.object(this.basepathQuestions).valueChanges();

  }

  SaveQuestions(questionObj: any) {
    return this.fireBase.object(this.basepathQuestions).set(questionObj);
  }

  SaveQuestionByVotingandQuestionId(votingId:number,questionId:number,data:any){
    return this.fireBase.object(this.basepathQuestions + "/" + votingId + "/Questions/" + questionId).set(data);
  }

  GetQuestionByVotingandQuestionId(votingId:number,questionId:number){
    return this.fireBase.object(this.basepathQuestions + "/" + votingId + "/Questions/" + questionId).valueChanges();
  }
  GetTypes() {
    return this.fireBase.object(this.basepathTypes).valueChanges();

  }
}
