// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {

    // apiKey: "AIzaSyCyQQM6GA_oN8Ga8JjbaLnYRCHHhzo8HpU",
    // authDomain: "surreybusinessexpo.firebaseapp.com",
    // databaseURL: "https://surreybusinessexpo-default-rtdb.europe-west1.firebasedatabase.app",
    // projectId: "surreybusinessexpo",
    // storageBucket: "surreybusinessexpo.appspot.com",
    // messagingSenderId: "959763789713",
    // appId: "1:959763789713:web:e22c900d5d5a3fe13fcc30",
    // measurementId: "G-NBEYHV5EY7"
    //Live Database
    apiKey: "AIzaSyC3n_TTZL1MNF-FgCHO2N_JVG0phPUZIsA",
    authDomain: "bsge-asm.firebaseapp.com",
    databaseURL: "https://bsge-asm.firebaseio.com",
    projectId: "bsge-asm",
    storageBucket: "bsge-asm.appspot.com",
    messagingSenderId: "621806618399",
    appId: "1:621806618399:web:cf0de17bbc66b6ed1d522a",
    measurementId: "G-T9JWG4NZCH"

    // //NAGA DATABASE
    // apiKey: "AIzaSyCiv8eH9qwcG3ZiwrBoTjm33FowUU-tMRE",
    // authDomain: "bsge-test-new-60e2f.firebaseapp.com",
    // databaseURL: "https://bsge-test-new-60e2f.firebaseio.com",
    // projectId: "bsge-test-new-60e2f",
    // storageBucket: "bsge-test-new-60e2f.appspot.com",
    // messagingSenderId: "733467799854",
    // appId: "1:733467799854:web:2896450bdd972cb55e4abf",
  

  },
  firebasePushnotificationAuthorizationKey:"AIzaSyDrW9UNSMaCzrcHfuNlj_fbrUTe0G1F8Js",
  firebaseFCmApi:"https://fcm.googleapis.com/fcm/send"
};
