import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { RoomsservicesService } from 'src/app/Services/roomsservices.service';
import { IRooms, Rooms } from './rooms';
import { IProgram, Program } from '../events/events';
import { FileUpload } from './../../Models/FileUpload';
import { Message } from 'primeng/api';
import { FormBuilder, Validators } from '@angular/forms';
import { UploadFileServiceService } from 'src/app/Services/upload-file-service.service';
import { EventsService } from 'src/app/Services/events.service';

@Component({
  selector: 'app-rooms',
  templateUrl: './rooms.component.html',
  styleUrls: ['./rooms.component.css']
})
export class RoomsComponent implements OnInit {
  promise: Promise<void>;
  msgs: Message[] = [];
  color: string;
  rooms: Rooms[] = [];

  selectedProgramFiles: any;
  roomData: Rooms[] = [];
  editItemRoom: any;
  RoomForm: any;

  addRoomFormStatus: boolean;

  ngOnInit(): void {
    this.LoadRooms();

  }


  constructor(private eventsService: EventsService, private fileUploadService: UploadFileServiceService,
    private formBuilder: FormBuilder, private roomsService: RoomsservicesService) {

  }




  LoadRooms() {
    this.roomsService.GetAllRooms()
      .subscribe(
        (profile: Rooms[]) => {
          if (profile != undefined && profile.length > 0) {
            this.rooms = profile.sort(x => x.DisplayOrder);;
            debugger
            console.log(this.rooms);
            // this.buildRoomPageForm(this.rooms);
          }
        },
        (error: any) => {
          alert(error);
        })
  }


  //#region on Program 
  buildRoomPageForm(programData) {
    const page = programData;
    this.RoomForm = this.formBuilder.group({

      RoomName: [page ? page.RoomName : '', Validators.required],
      IsRoomActive: [page ? page.IsRoomActive : false],
      Id: [page ? page.Id : ''],
      DisplayOrder: [page ? page.DisplayOrder : '', Validators.required],
      HexColor: [page ? page.HexColor : '']
    });
  }

  findSelectedItemIndex(item): number {
    console.log(item);
    return this.rooms.indexOf(item);
  }

  toggleAddRoomForm() {

    this.editItemRoom = null;
    this.RoomForm = this.formBuilder.group({

      RoomName: ['', Validators.required],
      Id: '',
      IsRoomActive: false,
      DisplayOrder: ['', Validators.required],
      HexColor:''
    });
    this.addRoomFormStatus = true;

  }



  editRoomForm(eventData) {
    this.editItemRoom = eventData;
    this.addRoomFormStatus = true;
    if (this.addRoomFormStatus) {
      this.color = this.editItemRoom.HexColor;
      this.buildRoomPageForm(this.editItemRoom)
    }

  }


  deleteRoomForm(rooms) {
    let index = this.findSelectedItemIndex(rooms);
    if (index >= 0) {
      this.rooms.splice(index, 1);
      this.roomsService.SaveRooms(this.rooms);
    }
  }

  onNewProgramSubmit(formData) {
    formData.value.HexColor = this.color;
    let eventDays = [];
    debugger

    if (this.rooms && this.rooms.length > 0) {
      eventDays = [...this.rooms];
    }
    if (this.editItemRoom) {
      eventDays[this.findSelectedItemIndex(this.editItemRoom)] = formData.value;
    } else {
      eventDays.push(formData.value);
    }

    if (!this.rooms) {
      this.rooms = [];
    }
    let valueIndex = eventDays.indexOf(formData.value);
    eventDays.forEach((item, index) => {
      
      if (index == valueIndex) {
        item.Id = valueIndex;
      }
    });

    this.promise = this.roomsService.SaveRooms(eventDays);
    console.log(eventDays);
    this.promise.then(_ => this.msgs.push({ severity: 'success', summary: 'Success Message', detail: 'Saved' }))
      .catch(err => this.msgs.push({ severity: 'error', summary: 'Error Message', detail: err }));
    this.toggleAddRoomForm['eventDays'] = eventDays;
    this.editItemRoom = null;
    this.color="";
    this.toggleAddRoomForm();
    this.addRoomFormStatus = false;

  }
}

