import { Component, OnInit } from '@angular/core';
import { Sessions } from '../sessions/sessions';
import { SessionsService } from 'src/app/Services/sessions.service';
import { QuestionsService } from 'src/app/Services/questions.service';
import { VotingQUestions, Questions } from '../questions/questions';
import { Router } from '@angular/router';
import { ConfigurationService } from 'src/app/Services/configuration.service';
import { Configuration } from '../configuration/configuration';

@Component({
  selector: 'app-vote',
  templateUrl: './vote.component.html',
  styleUrls: ['./vote.component.css']
})
export class VoteComponent implements OnInit {
  companyLogo: string;

  constructor(private sessionService: SessionsService, private questionService: QuestionsService, private route: Router,
    private configurationService: ConfigurationService) {
    this.roomId = parseInt(localStorage.getItem("RoomId"));
    this.programid = parseInt(localStorage.getItem("ProgramId"));
    this.sessionid = parseInt(localStorage.getItem("SessionId"));

    this.sessionService.GetSessions().subscribe(
      (session: Sessions[]) => {
        session = session.filter(x => x.SessionsId === this.sessionid);
        if (session != undefined) {
          this.session = session[0];
        }
      })


    this.LoadQuestions();

  }
  roomId: any;
  session: Sessions;
  programid: any;
  sessionid: any;
  questionList: Questions[] = [];
  questionsListNew: Questions[] = [];
  votingQuestion: VotingQUestions;
  question: Questions;
  qustionId: number[] = [];
  sampleData: any = [];
  questionIndex: number;
  showEnded: boolean = false;
  showSpinner: boolean = true;
  currentIndex: number = 0;

  ngOnInit() {
    this.LoadCompanyDetails();
  }


  LoadQuestions() {

    this.questionService.GetQuestions()
      .subscribe(
        (question: VotingQUestions[]) => {
          if (question != undefined && question.length > 0) {
            this.showSpinner = false;
            question = question.filter(x => x.RoomId === this.roomId && x.SessionId === this.sessionid &&
              x.ProgramId === this.programid);
            if (question != undefined) {
              this.questionList = question[0].Questions;
              this.questionsListNew = question[0].Questions;
              if (this.questionList != undefined && this.questionList.length > 0) {
                question[0].Questions = this.questionList;
                this.votingQuestion = question[0];
                this.question = this.questionList[this.currentIndex];
                this.LoadFirstQuestion();
              }
            }

          }
        })
  }

  LoadFirstQuestion() {
    if (this.questionList != undefined && this.questionList.length > 0) {
      if (this.question != undefined) {
        this.LoadGraphValue();
      }
      // if (this.question.IsCompleted != true) {
      //   this.question.IsActive = true;
      //   this.questionIndex = this.question.QuestionId;
      //   this.questionService.SaveQuestionByVotingandQuestionId(this.votingQuestion.VotingId, this.questionIndex, this.question);
      //   this.LoadGraphValue();
      // }
    }

  }


  LoadGraphValue() {
    this.sampleData = [];
    for (var i = 0; i < this.question.Answers.length; i++) {
      if (this.question.SubbmitedAnswers != undefined && this.question.SubbmitedAnswers.length > 0) {
        let totalCount = 0;
        totalCount = this.cartTotal();
        for (var j = 0; j < this.question.SubbmitedAnswers.length; j++) {
          if (i == j) {
            this.sampleData.push({ Answer: this.question.Answers[i], Count: this.question.SubbmitedAnswers[j], Total: totalCount })
          }
        }
      }
    }
  }

  public cartTotal(): number {

    let total: number = 0;
    this.question.SubbmitedAnswers.forEach((e: any) => {
      total = total + Number(e);
    });

    return total;
  }

  Next() {
    debugger
    if (this.questionList != undefined) {
      this.question = this.questionList[this.currentIndex];
      if (this.question != undefined) {
        this.question.IsActive = false;
        this.question.IsCompleted = true;
        this.questionService.SaveQuestionByVotingandQuestionId(this.votingQuestion.VotingId, this.question.QuestionId, this.question);
        this.currentIndex++;
        this.question = this.questionList[this.currentIndex];
        if (this.question != undefined) {
          this.question.IsActive = true;
          this.question.IsCompleted = false;
          this.questionService.SaveQuestionByVotingandQuestionId(this.votingQuestion.VotingId, this.question.QuestionId, this.question);
          this.LoadNextQuestions();
        }
        else {
          this.route.navigate(['/thanks'])
        }


      }
      else {
        this.showEnded = true;
      }
    } else {
      this.showEnded = true;
    }


    //original
    // debugger

    // if (this.questionList != undefined) {
    //   this.question = this.questionList[0];
    //   this.question.IsActive = false;
    //   this.question.IsCompleted = true;
    //   this.questionService.SaveQuestionByVotingandQuestionId(this.votingQuestion.VotingId, this.question.QuestionId, this.question);
    //   this.questionList.shift();
    //   this.question = this.questionList[0];

    //   this.question.IsActive = true;
    //   this.question.IsCompleted = false;
    //   this.questionService.SaveQuestionByVotingandQuestionId(this.votingQuestion.VotingId, this.question.QuestionId, this.question);
    //   this.LoadGraphValue();
    // }
    // else {
    //   debugger
    //   this.sampleData = [];
    //   this.question = null;
    //   this.showEnded = true;
    // }

  }

  Restart() {
    this.currentIndex = 0;
    this.questionsListNew.forEach(x => {
      let answers = [];
      x.IsActive = false;
      x.IsCompleted = false;
      x.SubbmitedAnswers.forEach((e: any) => {
        answers.push(0);
      });
      x.SubbmitedAnswers = answers;
      this.questionService.SaveQuestionByVotingandQuestionId(this.votingQuestion.VotingId, x.QuestionId, x);
    })
    this.question = this.questionsListNew[this.currentIndex];
    this.question.IsActive = true;
    this.question.IsCompleted = false;
    this.questionService.SaveQuestionByVotingandQuestionId(this.votingQuestion.VotingId, this.question.QuestionId, this.question);
    this.LoadQuestions();
  }


  Previous() {
    debugger
    if (this.currentIndex >= 0) {
      this.question = this.questionList[this.currentIndex];
      if (this.question != undefined) {
        this.question.IsActive = false;
        this.question.IsCompleted = false;
        this.questionService.SaveQuestionByVotingandQuestionId(this.votingQuestion.VotingId, this.question.QuestionId, this.question);
        this.currentIndex--;
        this.question = this.questionList[this.currentIndex];
        this.question.IsActive = true;
        this.question.IsCompleted = false;
        this.questionService.SaveQuestionByVotingandQuestionId(this.votingQuestion.VotingId, this.question.QuestionId, this.question);

      }
      else {
        this.questionService.GetQuestions()
          .subscribe(
            (question: VotingQUestions[]) => {
              if (question != undefined && question.length > 0) {
                debugger
                this.questionList = question[0].Questions;
                question = question.filter(x => x.RoomId === this.roomId && x.SessionId === this.sessionid &&
                  x.ProgramId === this.programid);

                this.question = question[0].Questions[this.currentIndex];

                this.question.IsActive = true;
                this.question.IsCompleted = false;
                this.questionService.SaveQuestionByVotingandQuestionId(this.votingQuestion.VotingId, this.question.QuestionId, this.question);
                this.currentIndex--;


              }
            })
      }

    }
  }
  LoadNextQuestions() {
    if (this.questionList != undefined && this.questionList.length > 0) {

      this.question = this.questionList[this.currentIndex];
      this.LoadGraphValue();

    }

    //original

    // if (this.questionList != undefined) {
    //   this.qustionId = [];

    //   this.questionList.forEach(x => {
    //     if (!x.IsCompleted)
    //       this.qustionId.push(x.QuestionId);
    //   })
    //   this.questionIndex = this.qustionId[0];
    //   this.question = null;
    //   //To Load Next question

    //   this.questionService.GetQuestionByVotingandQuestionId(this.votingQuestion.VotingId, this.qustionId[0]).subscribe((quest: Questions) => {
    //     this.question = quest;
    //     this.question.IsActive = true;
    //     this.question.IsCompleted = false;
    //     this.questionService.SaveQuestionByVotingandQuestionId(this.votingQuestion.VotingId, this.qustionId[0], this.question);

    //   });
    //   this.LoadGraphValue();
    // }

  }

  LoadCompanyDetails() {

    this.configurationService.getConfiguration()
      .subscribe(
        (config: Configuration) => {
          this.companyLogo = config.CompanyLogo
        })
  }
}
