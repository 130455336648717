export interface IQuestions {
  
    Question: string;  
    SubbmitedAnswers: number[];
    Type: number;
    UsersId: string;
    IsCompleted:boolean;
    Answers:string[];
    IsActive: boolean,
    QuestionId:number;
}

export class Questions implements IQuestions {
    Answers:string[];
    IsActive: boolean;
    Question: string;
    SubbmitedAnswers:number[];
    Type: number;    
    IsCompleted:boolean;
    UsersId: string;
    QuestionId:number;
}


export interface IVotingQUestions {
    RoomId: number;
    ProgramId: number;
    SessionId: number;
    RoomName: string;
    ProgramName: string;
    SessionName: string;
    Questions:Questions[];
    Active:boolean;
    VotingId:number;
    SessionStartTime:string;
    SessionEndTime:string;
    Day:string;
    DayText:string;
}

export class VotingQUestions implements IVotingQUestions{
    RoomId: number;  
    ProgramId: number;
    SessionId: number;
    Questions:Questions[];
    Active:boolean;
    RoomName: string;
    ProgramName: string;
    SessionName: string;
    VotingId:number;
    SessionStartTime:string;
    SessionEndTime:string;
    Day:string;
    DayText:string;
}