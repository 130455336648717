import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
  providedIn: 'root'
})
export class CategoryPageService {

  baseCategoryPagePath:string = "/CategoryPages/";
  constructor(private fireBase: AngularFireDatabase) { }

  GetAllCategoryPages() {
    return this.fireBase.object(this.baseCategoryPagePath).valueChanges();
  }

  SaveCategoryPages(data:any){
    return this.fireBase.object(this.baseCategoryPagePath).set(data);
  }

  
}
