import { Component, OnInit } from '@angular/core';
import { GuidGenerator } from '../../Models/GuidGenerator'
import { FormBuilder, Validators } from '@angular/forms';
import { Message, SelectItem } from 'primeng/api';
import { CategoryPage } from './categorypage';
import { CategoryPageService } from 'src/app/Services/categorypage.service';
import { CategoryService } from 'src/app/Services/category.service';
import { Category } from '../category/category';

@Component({
  selector: 'app-category-pages',
  templateUrl: './category-pages.component.html',
  styleUrls: ['./category-pages.component.css']
})
export class CategoryPagesComponent implements OnInit {
  promise: Promise<void>;
  msgs: Message[] = [];
  categoryPages: CategoryPage[] = [];
  categoryPageData: CategoryPage[] = [];
  editItemCatgeoryPage: any;
  CategoryPageForm: any;
  categories: Category[] = [];
  addCategoryPageFormStatus: boolean;
  IsExternalLink: boolean = false;
  //categoryDropDown: SelectItem[]=[];

  ngOnInit(): void {
    this.LoadCategoryPages();
    //this.LoadCategory();

  }


  constructor(
    private formBuilder: FormBuilder, private categoryPagesService: CategoryPageService, private categoryService: CategoryService, ) {

  }


  // LoadCategory() {
  //   let valueData = { label: " -- Select --", value: null }    
  //   this.categoryDropDown.push(valueData);
  //   this.categoryService.GetAllCategory()
  //     .subscribe(
  //       (categories: Category[]) => {
  //         categories.forEach((item1) => {

  //           let value = {
  //             label: item1.Title,
  //             value: item1.CategoryId,

  //           }
  //           this.categoryDropDown.push(value)

  //       });
  //       },
  //       (error: any) => {
  //         alert(error);
  //       })
  // }


  LoadCategoryPages() {

    this.categoryPagesService.GetAllCategoryPages()
      .subscribe(
        (categoryPages: CategoryPage[]) => {

          this.categoryPages = categoryPages;
        },
        (error: any) => {
          alert(error);
        })
  }


  //#region on Program 
  buildCategoryPageForm(programData) {
    const page = programData;
    this.CategoryPageForm = this.formBuilder.group({
      CategoryPageId: [page ? page.CategoryPageId : ''],
      Title: [page ? page.Title : '', Validators.required],
      ShortDescription: [page ? page.ShortDescription : ''],
      Description: [page ? page.Description : ''],
      Active: [page ? page.Active : false],
      CategoryId: [page ? page.CategoryId : ''],
      ExternalLink: [page ? page.ExternalLink : ''],
      IsExternalLink:[page ? page.ExternalLink : false],
    });
  }

  findSelectedItemIndex(item): number {
    console.log(item);
    return this.categoryPages.indexOf(item);
  }

  toggleAddCategoryPageForm() {
    this.editItemCatgeoryPage = null;
    this.CategoryPageForm = this.formBuilder.group({
      CategoryPageId: '',
      Title: ['', Validators.required],
      ShortDescription: '',
      Description: '',
      Active: false,
      CategoryId: [''],
      ExternalLink: '',
      IsExternalLink:false
    });
    this.addCategoryPageFormStatus = true;

  }



  editCategoryPageForm(eventData) {
    this.IsExternalLink=false;
    this.editItemCatgeoryPage = eventData;
    this.addCategoryPageFormStatus = true;
    if (this.addCategoryPageFormStatus) {
      if (eventData.ExternalLink != undefined && eventData.ExternalLink != '') {
        this.IsExternalLink = true;
      }
      this.buildCategoryPageForm(this.editItemCatgeoryPage)
    }

  }


  deleteCategoryPageForm(categoryPages) {
    let index = this.findSelectedItemIndex(categoryPages);
    if (index >= 0) {
      this.categoryPages.splice(index, 1);
      this.categoryPagesService.SaveCategoryPages(this.categoryPages);
    }
  }

  onSubmit(formData) {
    let eventDays = [];
    if (this.IsExternalLink === false) {
      formData.value.ExternalLink = null;
      formData.value.IsExternalLink=false;
    }
    if (this.categoryPages && this.categoryPages.length > 0) {
      eventDays = [...this.categoryPages];
    }
    if (this.editItemCatgeoryPage) {
      eventDays[this.findSelectedItemIndex(this.editItemCatgeoryPage)] = formData.value;
    } else {
      formData.value.CategoryPageId = GuidGenerator.GenerateGuid();
      eventDays.push(formData.value);
    }

    if (!this.categoryPages) {
      this.categoryPages = [];
    }

    this.promise = this.categoryPagesService.SaveCategoryPages(eventDays);
    console.log(eventDays);
    this.promise.then(_ => this.msgs.push({ severity: 'success', summary: 'Success Message', detail: 'Saved' }))
      .catch(err => this.msgs.push({ severity: 'error', summary: 'Error Message', detail: err }));
    this.toggleAddCategoryPageForm['eventDays'] = eventDays;
    this.editItemCatgeoryPage = null;
    this.toggleAddCategoryPageForm();
    this.addCategoryPageFormStatus = false;
    this.IsExternalLink = false;
  }

  ExternalEventFunc(eveent){
    this.IsExternalLink=eveent;
  }
}
