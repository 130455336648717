import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  baseConfigurationPath:string = "/CompanyConfiguration/";
  constructor(private fireBase: AngularFireDatabase) { }
  
  getConfiguration() {
    return this.fireBase.object(this.baseConfigurationPath).valueChanges();
  }

  saveConfiguration(data:any){
    return this.fireBase.object(this.baseConfigurationPath).set(data);
  }

}
